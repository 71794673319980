<template>
  <div id="connexionForm" :class="{ 'dansHeader': header, 'dansFooter': !header }">

    <div id="container-form" class="">


        <div :class="{'edition':1, 'hasMBN':markOrientest && isPro}" :id="nameDiv">
<!--
Attention aux ID, le même form en header et footer !!!
            <form id="connexionEditionForm" ref="connexionEditionForm" class="login_form" method="post" :action="lURLConnexion" accept-charset="utf-8" @submit="validerMonFormulaire">
-->
            <form class="login_form"  method="post" :action="lURLConnexion" accept-charset="utf-8" @submit="validerMonFormulaire">

                    <div class="blocConnexion">
                        <v-text-field
                            label="Adresse courriel"
                            class="adresse_mail"
                            name="data[Utilisateur][adresse_mail]"
                            v-model="form.adresse_mail"
                            :error-messages="error.adresse_mail"
                            ref="adresse_mail"
                        ></v-text-field>
<!--
// 2022/01 plus de validation de forme ... pour le moment
                            required
-->
                        <a :href="inscriptionProPasPro" tabindex="3" class="mdp_oublie" target="_top">{{labelPasDeCompte}}</a>
                    </div>

                    <div class="blocConnexion blocPasswordConnexion">

                        <v-text-field
                            v-if="showPassword"
                            label="Mot de passe"
                            type="text"
                            class="mot_de_passe"
                            name="data[Utilisateur][mot_de_passe]"
                            v-model="form.mot_de_passe"
                            :error-messages="error.mot_de_passe"
                            ref="mot_de_passe"
                        ></v-text-field>
                        <v-text-field
                            v-if="!showPassword"
                            label="Mot de passe"
                            type="password"
                            class="mot_de_passe"
                            name="data[Utilisateur][mot_de_passe]"
                            v-model="form.mot_de_passe"
                            :error-messages="error.mot_de_passe"
                            ref="mot_de_passe"
                        ></v-text-field>
<!--
// 2022/01 plus de validation de forme ... pour le moment
                            required
-->

                <img alt="Voir le mot de passe" class="iconeMotDePasse" v-if="!showPassword" src="../assets/img/oeil.svg"  @click="toggleShow" />
                <img alt="Voir le mot de passe" class="iconeMotDePasse" v-if="showPassword" src="../assets/img/oeilb.svg"  @click="toggleShow" />

<!--
// 2022/01 plus de font awesome ... pour le moment
                        <i class="iconeMotDePasse fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" @click="toggleShow"></i>
-->

                        <a :href="oublieProPasPro" tabindex="4" class="mdp_oublie" target="_top">Mot de passe oublié ?</a>
                    </div>

                    <div class="boutonsValidation">
                        <v-btn class="a-btn prioritaire registerBD" type="submit" @click="prevaliderMonFormulaire">Me connecter</v-btn>
                    </div>
                    <div class="MBN" v-if="markOrientest && isPro && 0">
                        -
                    </div>
                    <div class="MBN" v-if="markOrientest && isPro">
                        Se connecter avec
                        <a :href="lienMBN" class="logo-ent" title="Mon bureau numérique">
                            <img src="../assets/img/pro/logoENT.png" title="Pour le personnel des collèges et lycées" alt="Mon bureau numérique" />
                        </a>
                    </div>


                </form>
        </div>

    </div>

  </div>
</template>


<script>

import { addeoPost } from "../js/datas.js"

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, minValue, maxValue } from 'vuelidate/lib/validators'



export default {
    name: 'connexionForm',
    data() {
        return {
            showPassword: false,
            form:  {
                adresse_mail:" ",
                mot_de_passe:" "
                /* si correction 2022/02 pour Chrome
                adresse_mail:"",
                mot_de_passe:""
                */
            },
            data:  {
                Utilisateur:  {
                    adresse_mail:"",
                    mot_de_passe:""
                }
            },
            old: {

            },
            error: {
                mot_de_passe: '',
                adresse_mail: '',
            },
            ready:false,
        }
    },
    components: {
    },
    props: {
        header: Boolean,
/*
        sitePro: {
            type: Boolean
        },
*/
    },
    created() {
/*
        if (this.sitePro) {
            this.$parent.sitePRO = true;
        }

        if (this.sitePRO && this.lesParams == 'AgendaPro') {
        }
*/
        this.data["Utilisateur"] = this.form;

    },

    mounted() {
        setTimeout(this.reinit, 100);
    },

    validations() {
        return {
            form: {
                mot_de_passe: { required, maxLength: maxLength(65) },
                adresse_mail: { required, email },
            }
        }
    },
    computed: {
        lienMBN() {
            if (this.isProdProMBN) {
                return("https://connexev3.grandest.fr/gecas/login?service=https://mon.orientest.fr/&ent=true");
            }
            return("https://connexev3.recette.grandest.fr/gecas/login?service=https://preprod.monorientest.fr/&ent=true")  ;
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        nameDiv() {
            if (this.header) {
                return("divHeader");
            }
            return("divFooter");
        },
        inscriptionProPasPro() {
            var urlInscription = this.lURL;
            urlInscription += "/utilisateurs/inscription";
            if (this.$parent.sitePRO) {
                urlInscription += "_pro"
            }
            return(urlInscription);
        },
        labelPasDeCompte() {
            if (this.$parent.sitePRO) {
                return("Pas encore de compte PRO ?");
            }
            else {
                return("Pas encore de compte ?");
            }
        },
        oublieProPasPro() {
            var urlOublie = this.lURL;
            urlOublie += "/utilisateurs/raz_mdp_initial";
            if (this.$parent.sitePRO) {
                urlOublie += "/pro"
            }
            return(urlOublie);
        },

        lesPros() {
            if (this.markOrientest) {
                return("/login_pro");
            }
            else {
                return("/utilisateurs/identification/0/pro");
            }
        },
        lesAutres() {
            if (this.markOrientest) {
                return("/login");
            }
            else {
                return("/utilisateurs/identification");
            }
        },
        isProdProMBN() {
            var leDomaine = window.location.hostname;
            if ((leDomaine === "orientest.pro") || (leDomaine === "www.orientest.pro")) {
                return(1);
            }
            return(0);
        },
        lURL() {
            var leDomaine = window.location.hostname;

            if (this.markOrientest) {
                // quel domaine
                if ( leDomaine.indexOf("localhost") >= 0 ) {
                    return("https://grandtest.addeo.ovh"); // URL de test
                }
                else {
                    if (leDomaine.indexOf("grandtest") >= 0) {
                        return("https://grandtest.addeo.ovh"); // URL de test
                    }
                    else {
                        if ( (leDomaine !== "mon.orientest.fr") && (leDomaine !== "www.mon.orientest.fr") && (leDomaine !== "monorientest.fr") && (leDomaine !== "www.monorientest.fr") && (leDomaine !== "orientest.pro") && (leDomaine !== "www.orientest.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                            return("https://preprod.mon.orientest.fr"); // URL de preprod
                        }
                        else {
                             if ((leDomaine === "monorientest.fr") || (leDomaine === "www.monorientest.fr") || (leDomaine === "orientest.pro") || (leDomaine === "www.orientest.pro")) {
                                return('https://mon.orientest.fr'); // URL de prod
                             }
                        }
                    }
                }
            }
            else {
                // quel domaine
                if ( leDomaine.indexOf("localhost") >= 0 ) {
                    return("https://grandtest.addeo.ovh"); // URL de test
                }
                else {
                    if (leDomaine.indexOf("grandtest") >= 0) {
                        return("https://grandtest.addeo.ovh"); // URL de test
                    }
                    else {
                        if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                            return("https://preprod.lorfolio.fr"); // URL de preprod
                        }
                        else {
                             if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
                                return('https://mon.lorfolio.fr'); // URL de prod
                             }
                        }
                    }
                }
            }

        },
        lURLConnexion() {
            var urlIdentification = this.lURL;
            // pro ou pas pro ?
            if (this.$parent.sitePRO) {
                urlIdentification += this.lesPros;
            }
            else {
                urlIdentification += this.lesAutres;
            }
            return(urlIdentification);
        },
        isPro() {
            return(this.$parent.sitePRO);
        }

    },
    methods: {
        reinit() {
            this.form.adresse_mail = "";
            this.form.mot_de_passe = "";
/*  Cette correction ne marche pas - l'appli ne "voit" pas les contenus remplis par Chrome (bug Chrome ?)
            // 2022/02 - Dans le cas où Chrome alimente lui-même le formulaire, la structure de données de VUE n'est pas mise à jour
            // et cela induit :
            // 1/ un problème graphique
            // 2 un problème sur le contenu
            // solution -> on alimente nos données si les inputs ne sont pas vides
            const elHeader = document.getElementById('divHeader');
            if (elHeader) {
                const adresseMailInput = elHeader.querySelector('.adresse_mail input');
                if (adresseMailInput) {
                    const adresseMailInputValue = adresseMailInput.value;
                    if (adresseMailInputValue !== "") {
                        this.form.adresse_mail = adresseMailInputValue;
                    }
                }
                const motDePasseInput = elHeader.querySelector('.mot_de_passe input');
                if (motDePasseInput) {
                    const motDePasseInputValue = motDePasseInput.value;
                    if (motDePasseInputValue !== "") {
                        this.form.mot_de_passe = motDePasseInputValue;
                    }
                }
            }

            const elFooter = document.getElementById('divFooter');
            ... comme au dessus
*/
            this.data["Utilisateur"] = this.form;
        },

        toggleShow() {
          this.showPassword = !this.showPassword;
        },

        prevaliderMonFormulaire(e) {
/*
// 2022/01 plus de validation de forme ... pour le moment
            var thisObj = this;
            // on reinit à vide les messages d'erreur
            thisObj.reinitErrorMessages();

            // si pb de validation, on retourne false et on définit les messages d'erreur
            if (thisObj.$v.$invalid) {
                // maj erreurs
                thisObj.motPasseErrors();
                thisObj.adresseMailErrors();
                 e.preventDefault();
                return false;
            }
*/
            return true;

        },
        validerMonFormulaire(e) {
// 2022/01 plus de validation de forme ... pour le moment
/*
            // si pb de validation, on sort
            if (this.$v.$invalid) {
                 e.preventDefault();
                return false;
            }
*/
            return true;
        },

        // validation du formulaire
        reinitErrorMessages() {
            this.error.adresse_mail = ''
            this.error.mot_de_passe = ''
        },


        adresseMailErrors () {
            const errors = [];
            if (!this.$v.form.adresse_mail.email || this.$v.form.adresse_mail.$invalid) {
                this.error.adresse_mail = "Vous devez saisir une adresse courriel valide";
                this.$refs.adresse_mail.focus();
                return false;
            }
            else {
                this.error.adresse_mail = "";
            }
            return true;
        },
        motPasseErrors () {
            const errors = [];
            if (this.$v.form.mot_de_passe.$invalid) {
                this.error.mot_de_passe = "Vous devez saisir votre mot de passe";
                this.$refs.mot_de_passe.focus();
                return false;
            }
            else {
                this.error.mot_de_passe = "";
            }
            return true;
        },

    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .addeoSmall {

        #connexionForm {
            #container-form {
                text-align: center;

                div.blocConnexion {
                    text-align: left;
                    display:block;
                    width: 90%;
                    margin:0 auto 1em;
                }

                .boutonsValidation {
                    float:none;
                    width:100%;
                    text-align: center;
                    button {
                        margin:0 auto;
                    }
                }
                .MBN {
                    text-align: center;
                    display:block;
                    border:none;
                    margin:15px auto 0;
                    padding-top:15px;
                    padding-left:0;
                    border-top:1px solid #ccc;
                    max-width:100px;
                    a.logo-ent {
                        display:flex;
                        margin:0.6em auto;
                    }
                }
            }
        }
    }

    .addeoMedium:not(.addeoLargeLow) {
        #connexion {
            .containerConnexion {
                max-width: 700px;
                width:75%;
                margin:0 20px 0 150px;
                float:right;


                @media screen and (max-width: 760px) {
                    width:90%;
                    margin:0 5%;
                }

                #connexionForm {
                    #container-form {
                        .blocConnexion {
                            font-size:90%;
                            width: 35%;
                            max-width: 207px;

                            .v-input {
                                .v-input__slot, .v-text-field__slot {
                                    label {
                                        font-size:85%;
                                    }
                                }
                            }
                        }
                        .hasMBN {
                            .blocConnexion {
                                width: 26%;
                                max-width: 190px;
                                }
                            .boutonsValidation {
                                button {
                                    padding-left: 4px;
                                    padding-right: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #connexion {
        &.header {
            background-color: white;
        }
    }

    #connexionForm {
        font-family: Roboto, Arial, sans-serif;
        $couleur-fond: #eee;
        $couleur-texte: #223938;
        display:inline-block;
        width: 100%;


        div {
            display:inline-block;
        }

        #container-form {
            text-align: center;
            width: 100%;

            div.consigne {
                margin:1em 0;
                font-size: 0.9em;
            }
            .consigne {
                font-style: italic;
                display: inline-block;
            }

            div.edition {
                width: 100%;
                max-width: 600px;
                /*margin: 1.0em auto 0.8em;*/
                margin: 10px auto 10px;
                /*text-align: left;*/
                text-align: right;
                &.hasMBN {
                    max-width: 710px;
                }
            }

            .blocConnexion {
                width: 38%;
                max-width: 222px;
                margin: 0 2% 0 0;
                vertical-align: top;

                .iconeMotDePasse {
                    position: absolute;
                    top: 12px;
                    right: 10px;
                    cursor: pointer;
                    /*font-size: 120%;*/
                    max-width: 20px;
                    opacity: .7;
                    /*fill: #356a73;*/
                    &:hover {
                        opacity: 1;
                    }
                }
                &.blocPasswordConnexion {
                    .v-text-field {
                        .v-text-field__slot {
                            input {
                                width: 78%;
                            }
                        }
                    }
                }
                .v-input {
                    .v-input__slot, .v-text-field__slot {
                        input {
                            font-size:80%;
                        }
                        label {
                            font-size:90%;
                        }
                    }
                }
            }

            .v-input {
                display:inline-block;
                margin: 0;
                padding:0;
                /*
                width: 33%;
                max-width: 280px;
                */
                width: 100%;

                .v-input__slot, .v-text-field__slot {
/*
                    background-color: #F8F8FC;
                    color: #2C3E50;
*/
                    background-color:#EBEBEB;
                    /*color: #2C3E50;*/
                    color:#252525;

                    border-radius: 4px 4px 0 0;
                    width:100%;
                    margin-bottom:0;

                    input, textarea {
                        /*font-size:85%;*/
                        font-size:100%;
                        margin:0 5%;
                        /*padding:1.2em 0 0.4em;*/
                        padding:1.0em 0 0.1em;
                        width:90%;
                        &:focus, :focus {
                            outline:transparent solid 0px !important;
                        }
                    }
                    label {
                        left:1em !important;
                        top:0.6em !important;
                        right:auto !important;
                        color:#356A73;
                        /*color:#252525;*/
                        width: 100%;
                    }
                    .v-label--active {
                        transform: translateY(-8px) scale(.6);
                        left: -15% !important;
                        right:unset !important;
                    }
                }
                .v-text-field__details {
                    height: 9px;
                    /*
                    position: absolute !important;
                    top: -15px;
                    */
                    left: 0;
                    .v-messages {
                        min-height: 0px;
                        display:inline;
                        .v-messages__message {
                            color:darkorange;
                            font-size: 85%;
                            vertical-align: top;
                        }
                    }
                }
            }
            .v-input:not(.v-input--is-label-active) {
                .v-input__slot {
                    .v-text-field__slot {
                        input {
                            color:transparent;
                        }
                    }
                }
            }
            .boutonsValidation {
                font-family: Montserrat, Roboto, Arial, sans-serif;
                vertical-align: top;
                /*float: right;*/
                button {
                    font-size: 70%;
                    letter-spacing: 0.03em;
                    /*
                    padding: 0 10px;
                    margin-top:0.4em;
                    */
                    padding: 2px 9px;
                    margin-top:0;
                    height:30px;
                    text-transform: none;

                    color:white;
                    background-color: #356A73;
                    border-radius: 0;
                    box-shadow: none;
                    border: 1px solid #356A73;
                    .v-btn__content {
                        background-color: transparent;
                    }
                    &:hover, &:focus {
                        background-color: #fff;
                        color: #356a73;
                        &:before  {
                            opacity: 1;
                            background-color: transparent;
                            transition: none;
                        }
                    }
                }
            }
            a {
                font-size: 70%;
                text-align: right;
                display:block;
                margin-top:0.2em;

                color:white;
                text-decoration: underline;
                &:hover, &:focus {
                    text-decoration: none;
                    opacity: 0.8;
                }
            }

            .MBN {
                vertical-align: middle;
                text-align: center;
                font-size: 0.6em;
                margin-left: 20px;
                padding-left: 20px;
                border-left:1px solid #ddd;
                margin-top: -2px;
                a.logo-ent {
                    background-color: #4e76ae;
                    border-radius: 3px;
                    /*margin-left: 10px;*/
                    display: block;
                    transition-property: box-shadow,transform,opacity,-webkit-transform;
                    transition-duration: .28s;
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    width: 90px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 83%;
                    }

                    &:hover, &:focus {
                        background-color: #1e93c1;
                    }
                }
            }
        }

        &.dansHeader {
            #container-form {
                .blocConnexion {
                    a {
                        color:#356A73;
                        /*color:#252525;*/
                    }
                    .v-input {
                        .v-text-field__slot {
                            background-color:#EBEBEB;
                            color: #2C3E50;
                        }
                        label {
                            /*color:#356A73;*/
                        }
                    }
                }

                .boutonsValidation {
                    button {
                        /*
                        color:white;
                        background-color: #356A73;
                        */
                    }
                }
            }
        }
        &.dansFooter {
            #container-form {
                .boutonsValidation {
                    button {
                        &:hoverNAN {
                            border: 1px solid #fff;
                            background-color: #356A73;;
                            color: #fff;
                            /**/
                            background-color: #4b3c8f;
                            /**/
                        }
                    }
                }
            }
        }
    }

.markOrientest {
    #connexionForm {
        &.dansFooter {
            #container-form {
                .blocConnexion {
                    a {
                        color:#356A73;
                        /*color:#252525;*/
                    }
                    .v-input {
                        .v-text-field__slot {
                            background-color:white;
                            color: #2C3E50;
                        }
                        label {
                            /*color:#356A73;*/
                        }
                    }
                }

                .boutonsValidation {
                    button {
                        /*
                        color:white;
                        background-color: #356A73;
                        */
                    }
                }
            }
        }

    }
}



html {
    body {
        #app.addeoSmall, #app.addeoMedium, #app.addeoLarge  {
            #message.messageErreur {
                background-color: firebrick !important;
            }
        }
    }
}



/**/
    @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v67/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
    /*
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
    */
    /**/
</style>
