<template>
  <div id="inscription">
      <span class="titre">Pas encore de compte ?</span>
      <a
         @mouseover="hovered = true"
         @mouseleave="hovered = false"
         v-if="markOrientest" :href="urlInscription" class="bouton">
            Je crée un compte <img v-if="hovered" src="../assets/img/compte/logoMonOrientest.svg" alt="Mon OrientEst" class="centrer" /> <img v-if="!hovered" src="../assets/img/compte/logoMonOrientestBlanc.svg" alt="Mon OrientEst" class="centrer" />
        </a>
      <a v-if="!markOrientest" :href="urlInscription" class="bouton">Je crée mon compte Lorfolio</a>

  </div>
</template>

<script>
export default {
    name: 'Inscription',
    props: {
    },
    data() {
        return {
            hovered:false,
            tmp:true,
        }
    },
    computed: {
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        urlInscription() {
            var leDomaine = window.location.hostname;
            if (this.markOrientest) {
                if ( leDomaine.indexOf("localhost") >= 0 ) {
                    return("https://grandtest.addeo.ovh/utilisateurs/inscription"); // URL de test
                }

                if (leDomaine.indexOf("grandtest") >= 0) {
                    return("https://grandtest.addeo.ovh/utilisateurs/inscription"); // URL de test
                }

                if ( (leDomaine !== "mon.orientest.fr") && (leDomaine !== "www.mon.orientest.fr") && (leDomaine !== "monorientest.fr") && (leDomaine !== "www.monorientest.fr") && (leDomaine !== "orientest.pro") && (leDomaine !== "www.orientest.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                    return("https://preprod.monorientest.fr/utilisateurs/inscription"); // URL de preprod
                }
                else {
                     if ((leDomaine === "mon.orientest.fr") || (leDomaine === "www.mon.orientest.fr") || (leDomaine === "monorientest.fr") || (leDomaine === "www.monorientest.fr") || (leDomaine === "orientest.pro") || (leDomaine === "www.orientest.pro")) {
                        return('https://mon.orientest.fr/utilisateurs/inscription') // URL de prod
                     }
                }
            }
            else {
                if ( leDomaine.indexOf("localhost") >= 0 ) {
                    return("https://grandtest.addeo.ovh/utilisateurs/inscription"); // URL de test
                }

                if (leDomaine.indexOf("grandtest") >= 0) {
                    return("https://grandtest.addeo.ovh/utilisateurs/inscription"); // URL de test
                }

                if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                    return("https://preprod.lorfolio.fr/utilisateurs/inscription"); // URL de preprod
                }
                else {
                     if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
                        return('https://mon.lorfolio.fr/utilisateurs/inscription') // URL de prod
                     }
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #inscription {
        width:90%;
        height:auto;
        background: no-repeat center bottom transparent url("~@/assets/img/inscription/fond.jpg");
        text-transform: uppercase;
        padding:4em 5%;

        .titre {
            display:block;
            color: #4B3C8F;
            margin-bottom: 2em;
            font-weight: bold;
            font-size:1.2em;
        }
        a.bouton {
            display:inline-block;
            /*
            border: 1px solid #4B3C8F;
            background-color: #4B3C8F;
            */
            border: 1px solid #356a73;
            background-color: #356a73;
            color: white;
            padding: 1.2em 4.9em;
            font-size: 0.8em;
            font-weight: 600;
/*            line-height: 1.4em;*/
            img {
                margin-top:10px;
            }

            &:focus {
                opacity: 0.8;
            }
            &:hover {
                /*opacity: 0.5;*/
                background-color: white;
                /*
                color: #4B3C8F;
                */
                color: #356a73;
            }
        }
    }
</style>
