<template>
    <div id="avantages">
        <div class="containerAffichage">
            <h2 class="sousTitre" v-if="0 && markOrientest"><img src="../assets/img/header/logoOrientest.svg" alt="OrientEst" class="centrer marqueOrientest" /> s'engage</h2>
            <h2 class="sousTitre" v-if="markOrientest">Nos engagements</h2>
            <h2 class="sousTitre" v-if="!markOrientest"><img src="../assets/img/header/logoLorfolio.svg" alt="Lorfolio" class="centrer" /> s'engage</h2>

            <div id="items">

                    <item-avantage :data="lesDatas" item="gratuit" />
                    <item-avantage :data="lesDatas" item="libre" />
                    <item-avantage :data="lesDatas" item="durable" />
                    <item-avantage :data="lesDatas" item="personnel" />
                    <item-avantage :data="lesDatas" item="inclusif" />
                    <item-avantage :data="lesDatas" item="alecoute" class="dernier"/>

            </div>
        </div>
    </div>
</template>

<script>
    import Avantage from '@/components/avantage/Avantage.vue'
export default {
    name: 'Avantages',
    components: {
        'item-avantage': Avantage,
        },
    props: {
    },
    data() {
        return {
            images : {"gratuit":"img/avantages/gratuit.svg", "libre":"img/avantages/libre.svg", "durable":"img/avantages/durable.svg", "personnel":"img/avantages/personnel.svg", "inclusif":"img/avantages/inclusif.svg", "alecoute":"img/avantages/alecoute.svg"},
            itemsTitre : {"gratuit":"Gratuit", "libre":"Libre", "durable":"Durable", "personnel":"Personnel", "inclusif":"Inclusif", "alecoute":"À l'écoute"},
            itemsCont : {"gratuit":"Votre compte est offert par la Région Grand Est.<br/>Il n’y aura jamais de publicité, ni de vente ou exploitation de vos données.",
                         "libre":"Vous avez la maîtrise de votre compte.<br/>Vous avez entière liberté de le supprimer à tout moment. Vous avez le contrôle de vos données.<br/>Libre à vous d’autoriser quelqu’un à consulter et commenter les informations que vous souhaitez.",
                         "durable":"Vous conservez vos données tant que vous le souhaitez.<br/>Récupérez-les à tout moment dans un format informatique standard, ou sur des documents imprimables, pour les conserver ou les communiquer.<br/>Lorfolio : déjà 100 000 comptes créés depuis son ouverture.",
                         "personnel":"Vous êtes totalement propriétaire de vos informations.<br/>Personne ne peut les consulter sans votre autorisation. Lorfolio est protégé et respecte vos données privées.<br/>Tout est stocké en France, et principalement en Grand Est.",
                         "inclusif":"Lorfolio est ouvert à toutes et tous.<br/>Il est pensé pour fonctionner avec un débit internet limité et un équipement de base. Il utilise le vocabulaire le plus compréhensible possible. Son usage est possible avec une maîtrise simple des outils numériques. Il est en amélioration permanente pour favoriser l’accessibilité aux personnes en situation de handicap.<br/>Pour créer un compte, vous n’avez besoin que d’une adresse de courriel et d’un code postal en Grand Est. Rien de plus.",
                         "alecoute":"Une idée d’évolution, un bug, une question, une proposition de ressource ?<br/>Une fois votre inscription effectuée, Lorfolio assure un support permanent, et vous répond. Rapidement."},
            itemsContOrientest : {"gratuit":"Votre compte est offert par la Région Grand Est.<br/>Il n’y aura jamais de publicité, ni de vente ou exploitation de vos données.",
                         "libre":"Vous avez la maîtrise de votre compte.<br/>Vous avez entière liberté de le supprimer à tout moment. Vous avez le contrôle de vos données.<br/>Libre à vous d’autoriser quelqu’un à consulter et commenter les informations que vous souhaitez.",
                         "durable":"Vous conservez vos données tant que vous le souhaitez.<br/>Récupérez-les à tout moment dans un format informatique standard, ou sur des documents imprimables, pour les conserver ou les communiquer.<br/>Mon Orient'Est : déjà 130 000 comptes créés depuis son ouverture.",
                         "personnel":"Vous êtes totalement propriétaire de vos informations.<br/>Personne ne peut les consulter sans votre autorisation. Mon Orient'Est est protégé et respecte vos données privées.<br/>Tout est stocké en France, et principalement en Grand Est.",
                         "inclusif":"Mon Orient'Est est ouvert à toutes et tous.<br/>Il est pensé pour fonctionner avec un débit internet limité et un équipement de base. Il utilise le vocabulaire le plus compréhensible possible. Son usage est possible avec une maîtrise simple des outils numériques. Il est en amélioration permanente pour favoriser l’accessibilité aux personnes en situation de handicap.<br/>Pour créer un compte, vous n’avez besoin que d’une adresse de courriel et d’un code postal en Grand Est. Rien de plus.",
                         "alecoute":"Une idée d’évolution, un bug, une question, une proposition de ressource ?<br/>Une fois votre inscription effectuée, Mon Orient'Est assure un support permanent, et vous répond. Rapidement."},
            tmp:true
        }
    },

    computed: {
        lesDatas() {
            var datas = new Object();
            datas.images= this.images;
            datas.itemsTitre= this.itemsTitre;
            if (this.markOrientest) {
                datas.itemsCont= this.itemsContOrientest;
            }
            else {
                datas.itemsCont= this.itemsCont;
            }
            return(datas);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

.addeoSmall {
    #avantages {

        .item {

            img {
                width:10%;
                /*
                width:25%;
                float:none;
                display: block;
                margin: 1em auto 1em 0;
                */
            }
            .contenu {
                width:85%;
                margin-left:15%;
            }
            .titre, .contenu {
                /*width:100%;*/
                /*
                padding-left:10%;
                padding-right:5%;
                */
            }
        }
    }
}

    #avantages {
        width:100%;
        height:auto;
        background: no-repeat center top #EDF2F5 url("~@/assets/img/decoupage.png");
        text-align: center;
        padding:4em 0 2em;

        .dernier {
            .item {
                background-image: none;
            }
        }

        #items {
            padding-top:1em;
            max-width: 90%;
            margin:0 auto;
        }

        .item {
            display:block;
            width:100%;
            max-width:1100px;
            margin:2em auto 0;
            overflow: hidden;
            text-align: left;
            padding-bottom:2em;
            background: no-repeat center bottom transparent url("~@/assets/img/avantages/barre.svg");


            img {
                width:25%;
                max-width:94px;
                float:left;
                margin: 0 5% 0 0;
            }


            .titre, .contenu {
                float:left;
                font-weight: 600;

                span {
                }
            }

            .titre {

                width:50%;
                font-size:1.4em;
                text-transform: uppercase;
                font-weight: 600;
                color:#356974;
                margin-bottom: 0.4em;
                span {
                }
            }
            .contenu {
                width:70%;
                max-width:950px;
                font-weight: normal;
                color:#4B3C8F;
                span {
                    line-height: 1.8;
                    /*
                    width: 60ch;
                    display: inline-block;
                    */
                }
            }



        }








        /* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(30%);
  opacity: 0;
}

    }
</style>
