<template>
  <div id="header" v-bind:class="{markOrientest : markOrientest}">
    <div class="gauche"></div>
    <div class="droite"></div>
    <header-logos-pro v-if="sitePRO" />
    <header-logos v-if="!sitePRO && !markOrientest" />
    <div id="conteneurMonHeaderLogo" v-if="!sitePRO && markOrientest && addeoSmall">
        <header-logos />
    </div>
    <header-logos v-if="!sitePRO && markOrientest && !addeoSmall" />
    <bloc-publics v-if="!sitePRO" />
  </div>
</template>

<script>

import HeaderLogosPro from '@/components/pro/header/HeaderLogos.vue'
import HeaderLogos from '@/components/header/HeaderLogos.vue'
import Publics from '@/components/header/Publics.vue'

export default {
    name: 'Header',
    props: {
    },
    components: {
        'header-logos-pro': HeaderLogosPro,
        'header-logos': HeaderLogos,
        'bloc-publics': Publics,
    },
    data() {
        return {
            tmp:true,
            imgs:[]
        }
    },
    created() {
        this.preloadImages([
            "/img/oe/pro/cache/l-mots-croises.png",
            "/img/oe/pro/cache/m-641.png",
            "/img/oe/pro/cache/m-991.png",
            "/img/oe/pro/cache/s-320.png",
            "/img/oe/pro/cache/s-640.png",
            "/img/oe/pro/cache/l-persos-droite.png",
            "/img/oe/pro/cache/l-persos-gauche.png"
            ], function(){
                console.log('All images were loaded');
                //this.ready=1;
            });
    },
    computed: {
        addeoSmall() {
            return(this.$parent.$parent.addeoSmall);
        },
        sitePRO() {
            return(this.$parent.$parent.sitePRO);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    },

    methods: {
        preloadImages(urls, allImagesLoadedCallback){
            var thisObj = this;
            var loadedCounter = 0;
            var toBeLoadedNumber = urls.length;
            var counter = 0;
            urls.forEach(function(url){
                thisObj.preloadImage(url, counter, function(){
                    loadedCounter++;
                    // console.log('Number of loaded images: ' + loadedCounter);
                    if(loadedCounter == toBeLoadedNumber){
                        allImagesLoadedCallback();
                    }
                });
                counter++;
            });
        },
        preloadImage(url, counter, anImageLoadedCallback){
            var img = new Image();
            img.onload = anImageLoadedCallback;
            img.src = url;
            this.imgs.push = img;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #header {
        width:100%;
        height:auto;
        /*min-height:300px;*/
        background: no-repeat center bottom transparent url("~@/assets/img/header/fondh.jpg");
        .gauche, .droite {
            /*display:none;*/
        }
        .gauche {
            background: no-repeat right bottom transparent none;
            position:absolute;
            left:0;
            bottom:0;
            height:100%;
            width:37%
        }
        .droite {
            background: no-repeat right bottom transparent none;
            position:absolute;
            right:0;
            bottom:0;
            height:100%;
            width:35%
        }
        /*background-size:auto;*/
        /*background-size:cover;*/
        #publics {
            background: no-repeat center bottom transparent url("~@/assets/img/header/bas.png");
        }
    }
    .addeoSmall {
        #header {
            background-position: center center;
            background-color: #E0E5CE;

            .gauche, .droite {
                display:none;
            }
        }
    }
    .addeoLarge, .addeoMedium {
        #header {
            .gauche, .droite {
                display:block;
            }

@media screen and (max-width: 641px) {
            .gauche {
                background: no-repeat right bottom transparent url("~@/assets/img/header/perso-gauche.png");
            }
            .droite {
                background: no-repeat right bottom transparent url("~@/assets/img/header/perso-droite.png");
            }
}
            @media screen and (max-width: 1750px) {
                .gauche, .droite {
                    display:block;
                }
                .gauche {
                    background-position: right bottom;
                }
                .droite {
                    background-position: left bottom;
                    width:27%
                }
            }
            @media screen and (max-width: 1550px) {
                .gauche {
                    width:38%
                }
                .droite {
                    width:23%
                }
            }
        }
    }
    .addeoMedium {
        #header {
            @media screen and (max-width: 1800px) {
                .gauche {
                    width:40%
                }
            }
            @media screen and (max-width: 1600px) {
                .gauche {
                    width:45%
                }
            }
            @media screen and (max-width: 1400px) {
                .gauche {
                    width:48%
                }
                .droite {
                    width:27%
                }
            }
            @media screen and (max-width: 1100px) {
                .gauche {
                    width:52%
                }
                .droite {
                    width:28%
                }
            }


        }
    }
#app.addeoSmall:not(.pro) {
    .markOrientest {
        #conteneurMonHeaderLogo {
            background: no-repeat center center transparent url("~@/assets/img/header/fondh.jpg");
            #headerLogos {
                padding: 2em 2% 4em;
            }
        }
        #publics {
            margin-top:0;
        }
        #header {
            background: none;
        }
    }
}
</style>
<style lang="scss">
#app.addeoSmall:not(.pro ) {
    .markOrientest {
        #conteneurMonHeaderLogo {
            #headerLogos {
                #logoLorfolio {
                    &.itemLogo {
                        #prepare {
                            font-size: 1.28em;
                            @media screen and (max-width: 480px) {
                                font-size: 4.4vw;
                            }
                        }
                    }
                }
            }
        }
        #publics {
            #publicsContainerAffichage {
                margin-top:0;
            }
        }
    }
}

</style>
