import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

Vue.config.productionTip = false

import axios from 'axios'
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

import Vuetify from 'vuetify'
Vue.use(Vuetify);


import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
//import 'material-design-icons-iconfont/dist/material-design-icons.css'
//import 'mdi/css/materialdesignicons.css'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
//import '@mdi/font/css/materialdesignicons.css'

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

/**/
import Meta from 'vue-meta';
Vue.use(Meta);
/**/
new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount('#app')

//window._paq.push;
