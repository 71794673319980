<template>
    <div id="carouselBanniere">
        <a :href="banniere.lien" target="_blank">
            <div v-html="banniere.html" class="htmlCarouselBanniere" :style="styleLargeur"></div>
            <img v-if="addeoLarge" :src="banniere.image" :alt="banniere.alt" :width="laLargeur" height="auto" />
            <img v-if="addeoMedium" :src="banniere.imageM" :alt="banniere.alt" :width="laLargeur" height="auto" />
            <img v-if="addeoSmall" :src="banniere.imageS" :alt="banniere.alt" :width="laLargeur" height="auto" />
        </a>
    </div>
</template>

<script>

    export default {
        props: {
            banniere: {
                type: Object
            }
        },
        data() {
            return {
                tmp:true,
            }
        },
        computed:{
                styleLargeur() {
                    var toto = 1;
                    return("width:"+this.banniere.largeur+"px;");
                },
                laLargeur() {
                    var toto = 1;
                    return(""+this.banniere.largeur+"px;");
                },
                addeoSmall() {
                    return(this.$parent.$parent.$parent.$parent.$parent.addeoSmall);
                },
                addeoMedium() {
                    return(this.$parent.$parent.$parent.$parent.$parent.addeoMedium && !this.$parent.$parent.$parent.$parent.$parent.addeoLargeLow);
                },
                addeoLarge() {
                    return(this.$parent.$parent.$parent.$parent.$parent.addeoLarge || this.$parent.$parent.$parent.$parent.$parent.addeoLargeLow);
                },
        }
    }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #carouselBanniere {
        width:100%;
        height:auto;
        background-color: transparent;
        text-align: center;

        a {
            position: relative;
            display: block;
        }

        .htmlCarouselBanniere {
            color: #006EA9;
            font-size:1.6em;
            position:absolute;
            z-index:2;
            margin:0 auto;
            left:0;
            right:0;
            top:0;
            max-width:80%;

            .carouselTexteBig {
                font-weight: bold;
            }
            .carouselTexteSeparateurS, .carouselTexteSeparateurM {
                display:inline;
                max-height:0;
            }
            .carouselTexteSeparateur, .carouselTexteSeparateurSM, .carouselTexteSeparateurL {
                display:block;
            }
            .carouselTexteSeparateurSM {
                max-height:0;
            }
            .carouselTexteMev {
                text-decoration: underline;
            }
        }

        img {
            max-width:99%;
        }
    }

    .addeoSmall {
        #carouselBanniere {
            .htmlCarouselBanniere {
                font-size:1.0em;
                margin-top: 4%;
                line-height: 1.2em;

                .carouselTexteSeparateurL {
                    max-height:0;
                }
                .carouselTexteRetourS {
                    display:block;
                    max-height:0;
                }
                .carouselTexteSeparateurS {
                    display:block;
                    max-height:8px;
                }
                .carouselTexteSeparateur {
                    /*max-height:0;*/
                }
                .carouselTexteSeparateurSM {
                    max-height:none;
                }
            }
        }
    }
    .addeoMedium {
        #carouselBanniere {
            .htmlCarouselBanniere {
                font-size:1.2em;
                margin-top: 4%;

                .carouselTexteSeparateurL {
                    max-height:0;
                }
                .carouselTexteSeparateurM {
                    display:block;
                    max-height:0;
                }
                .carouselTexteSeparateur {
                    /*max-height:0;*/
                }
                .carouselTexteSeparateurSM {
                    max-height:none;
                }
            }
        }
    }
</style>
