<template>
  <div id="carouselContainer" v-bind:class="{markOrientest : markOrientest}">
  <div id="carousel"  v-bind:class="{plusDeQuatre : itemsCarousel.length > 4}">

      <carousel
        :autoplay="autoplay"
        :autoplayTimeout="3000"
        :autoplayHoverPause="true"
        :perPage="1"
        :navigationEnabled="false"
        :paginationEnabled="true"
        :loop="true"
        :paginationSize="13"
        paginationColor="#fff"
        >

        <slide v-for="itemCarousel in itemsCarousel"
          :key="itemCarousel.num"
          >
          <carousel-banniere :banniere="itemCarousel" />
        </slide>

      </carousel>

    <button
      v-if="isCarouselShowed"
      class="tdb-carousel--prevbutton"
      type="button"
      @click="prevCarousel"
    aria-label="Précédent"
      ></button>
    <button
      v-if="isCarouselShowed"
      class="tdb-carousel--nextbutton"
      type="button"
      @click="nextCarousel"
    aria-label="Suivant"
      ></button>
    <button
      v-if="isCarouselShowed"
      class="tdb-carousel--stopplaybutton"
      :class="stopOrPlayButtonClass"
      type="button"
      @click="stopPlayCarousel"
      ></button>


  </div>
  </div>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel'
    import CarouselBanniere from '@/components/carousel/CarouselBanniere.vue'

    export default {
        props: {
        },
        data() {
            return {
                tmp:true,
                banniere: {
                    "num":0,
                    "image":"",
                    "html":"",
                },
                autoplay: true,
            }
        },
        components: {
            'carousel-banniere': CarouselBanniere,
            Carousel,
            Slide
        },

        computed: {
            largeurCarousel() {
                if (this.addeoLarge) {
                    return(1140); // 1140 remplace 1090
                }
                if (this.addeoMedium) {
                    return(899); // 899 remplace 690
                }
                if (this.addeoSmall) {
                    return(340); // 340 remplace 450
                }
            },
            itemsCarousel() {
                var tabCarousel = [
                    {'num':1, 'image':'/'+'img/carousel/l_bandeau_apprentissage_ge.jpg', 'alt':'Apprentissage',
                     'imageM':'/'+'img/carousel/m_bandeau_apprentissage_ge.jpg', 'alt':'Apprentissage',
                     'imageS':'/'+'img/carousel/s_bandeau_apprentissage_ge.jpg', 'alt':'Apprentissage',
                     'html':'<span class="carouselTexteSeparateurS">&nbsp;</span><span class="carouselTexteSeparateurL">&nbsp;</span><span class="carouselTexteBig">Trouver un contrat<span class="carouselTexteRetourS">&nbsp;</span>d’apprentissage</span><span class="carouselTexteSeparateurL carouselTexteSeparateurM carouselTexteSeparateurS">&nbsp;</span><span class="carouselTexteMev">Des centaines d’offres<span class="carouselTexteRetourS">&nbsp;</span>en Grand Est</span>',
                     'largeur':this.largeurCarousel, 'lien':'https://www.apprentissage-grandest.fr/'},
                    {'num':2, 'image':'/'+'img/carousel/formation-ge-l.png', 'alt':'Formation',
                     'imageM':'/'+'img/carousel/formation-ge-m.png', 'alt':'Formation',
                     'imageS':'/'+'img/carousel/formation-ge-s.png', 'alt':'Formation',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.formation.grandest.fr/'},
                    {'num':3, 'image':'/'+'img/carousel/experiences-pro_l.jpg', 'alt':'OrientEst',
                     'imageM':'/'+'img/carousel/experiences-pro_m.jpg', 'alt':'OrientEst',
                     'imageS':'/'+'img/carousel/experiences-pro_s.jpg', 'alt':'OrientEst',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.orientest.fr'}
                ]/*}*/;
                var tabCarouselMarkOrientest = [
                    {'num':1, 'image':'/'+'img/oe/pro/carousel/apres3em_l.png', 'alt':'Je trouve ma formation après la 3ème en Grand Est',
                     'imageM':'/'+'img/oe/pro/carousel/apres3em_m.png', 'alt':'Je trouve ma formation après la 3ème en Grand Est',
                     'imageS':'/'+'img/oe/pro/carousel/apres3em_s.png', 'alt':'Je trouve ma formation après la 3ème en Grand Est',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.orientest.fr/actualites-orient-est/63-je-trouve-ma-formation-apres-la-3eme-en-grand-est?mtm_campaign=2023-02%20guide%203e&mtm_kwd=Guide&mtm_source=Carrousel%20monOrient%27Est&mtm_group=Jeune%2FParent'},
                    {'num':2, 'image':'/'+'img/oe/pro/carousel/experiences-pro_l.jpg', 'alt':'Je trouve mes premières expériences en entreprise',
                     'imageM':'/'+'img/oe/pro/carousel/experiences-pro_m.jpg', 'alt':'Je trouve mes premières expériences en entreprise',
                     'imageS':'/'+'img/oe/pro/carousel/experiences-pro_s.jpg', 'alt':'Je trouve mes premières expériences en entreprise',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.orientest.fr/actualites-orient-est/51-experiences-pro?mtm_campaign=experiences_pro&mtm_kwd=mon_orientest'},
                    {'num':3, 'image':'/'+'img/oe/pro/carousel/postbac_l.jpg', 'alt':'Mon avenir après la terminale',
                     'imageM':'/'+'img/oe/pro/carousel/postbac_m.jpg', 'alt':'Mon avenir après la terminale',
                     'imageS':'/'+'img/oe/pro/carousel/postbac_s.jpg', 'alt':'Mon avenir après la terminale',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.orientest.fr/actualites-orient-est/52-ress-post-bac?mtm_campaign=ress_post_bac&mtm_kwd=mon_orientest'},
                    {'num':4, 'image':'/'+'img/oe/pro/carousel/worldskills_l.jpg', 'alt':'Worldskills France',
                     'imageM':'/'+'img/oe/pro/carousel/worldskills_m.jpg', 'alt':'Worldskills France',
                     'imageS':'/'+'img/oe/pro/carousel/worldskills_s.jpg', 'alt':'Worldskills France',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.orientest.fr/actualites-orient-est/53-47worldskills?mtm_campaign=worldskills47&mtm_kwd=mon_orientest'}
                ];
                var tabCarouselMarkOrientestPro = [
                    {'num':1, 'image':'/'+'img/oe/pro/carousel/apres3em_l.png', 'alt':'Je trouve ma formation après la 3ème en Grand Est',
                     'imageM':'/'+'img/oe/pro/carousel/apres3em_m.png', 'alt':'Je trouve ma formation après la 3ème en Grand Est',
                     'imageS':'/'+'img/oe/pro/carousel/apres3em_s.png', 'alt':'Je trouve ma formation après la 3ème en Grand Est',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.orientest.fr/actualites-orient-est/54-je-trouve-ma-formation-apres-la-3eme-en-grand-est?mtm_campaign=formation_apres_3eme&mtm_kwd=orientest_pro'},
                    {'num':2, 'image':'/'+'img/oe/pro/carousel/experiences-pro_l.jpg', 'alt':'Je trouve mes premières expériences en entreprise',
                     'imageM':'/'+'img/oe/pro/carousel/experiences-pro_m.jpg', 'alt':'Je trouve mes premières expériences en entreprise',
                     'imageS':'/'+'img/oe/pro/carousel/experiences-pro_s.jpg', 'alt':'Je trouve mes premières expériences en entreprise',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.orientest.fr/actualites-orient-est/51-experiences-pro?mtm_campaign=experiences_pro&mtm_kwd=orientest_pro'},
                    {'num':3, 'image':'/'+'img/oe/pro/carousel/postbac_l.jpg', 'alt':'Mon avenir après la terminale',
                     'imageM':'/'+'img/oe/pro/carousel/postbac_m.jpg', 'alt':'Mon avenir après la terminale',
                     'imageS':'/'+'img/oe/pro/carousel/postbac_s.jpg', 'alt':'Mon avenir après la terminale',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.orientest.fr/actualites-orient-est/52-ress-post-bac?mtm_campaign=ress_post_bac&mtm_kwd=orientest_pro'},
                    {'num':4, 'image':'/'+'img/oe/pro/carousel/worldskills_l.jpg', 'alt':'Worldskills France',
                     'imageM':'/'+'img/oe/pro/carousel/worldskills_m.jpg', 'alt':'Worldskills France',
                     'imageS':'/'+'img/oe/pro/carousel/worldskills_s.jpg', 'alt':'Worldskills France',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.orientest.fr/actualites-orient-est/53-47worldskills?mtm_campaign=worldskills47&mtm_kwd=orientest_pro'},
                    {'num':5, 'image':'/'+'img/oe/pro/carousel/monoe_l.png', 'alt':'Mon Orient\'Est, toujours préparé pour mes choix professionnels',
                     'imageM':'/'+'img/oe/pro/carousel/monoe_m.png', 'alt':'Mon Orient\'Est, toujours préparé pour mes choix professionnels',
                     'imageS':'/'+'img/oe/pro/carousel/monoe_s.png', 'alt':'Mon Orient\'Est, toujours préparé pour mes choix professionnels',
                     'html':'',
                     'largeur':this.largeurCarousel, 'lien':'https://www.monorientest.fr?mtm_campaign=mon_orientest&mtm_kwd=orientest_pro'}
                ];

                if (this.markOrientest) {
                    if (this.sitePRO) {
                        return(tabCarouselMarkOrientestPro);
                    }
                    else {
                        return(tabCarouselMarkOrientest);
                    }
                }
                return(tabCarousel);
            },
            isCarouselShowed() {
                return this.itemsCarousel.length > 0
            },
            stopOrPlayButtonClass() {
                return this.autoplay ? 'pause' : 'play'
            },
            addeoSmall() {
                return(this.$parent.$parent.addeoSmall);
            },
            addeoMedium() {
                return(this.$parent.$parent.addeoMedium && !this.$parent.$parent.addeoLargeLow);
            },
            addeoLarge() {
                return(this.$parent.$parent.addeoLarge || this.$parent.$parent.addeoLargeLow);
            },
            markOrientest() {
                return(this.$router.options.markOrientest);
            },
            sitePRO() {
                return(this.$router.options.paramPRO);
            },
        },

        methods: {
            dotActive() {
                var elt = document.querySelector(".VueCarousel-dot--active");
                if (!elt) {
                    return(null);
                }
                var rang = elt.value.lastIndexOf(" ") + 1;
                var lEntier = parseInt(elt.value.substring(rang));
                if (isNaN(lEntier)) {
                    return(0);
                }
                return(lEntier);
            },
            stopPlayCarousel() {
                this.autoplay = !this.autoplay
            },
            nextCarousel() {
                var rangActif = this.dotActive();
                var elts = document.querySelectorAll(".VueCarousel-dot");
                if (rangActif == elts.length- 1) {
                    elts[0].click();
                    }
                else {
                    elts[rangActif+ 1].click();
                }
            },
            prevCarousel() {
                var rangActif = this.dotActive();
                var elts = document.querySelectorAll(".VueCarousel-dot");
                if (!rangActif) {
                    elts[elts.length- 1].click();
                    }
                else {
                    elts[rangActif- 1].click();
                }
            }
        }

    }

    // Trouvez votre contrat d’apprentissage<br/>Des centaines d’offres en Grand Est
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped lang="scss"> -->
<style  lang="scss">
    $largeurCarousel: 1140px; // 1140 remplace 1090px
    $largeurCarouselM: 899px; // 899 remplace 690px
    $largeurCarouselS: 340px; // 340 remplace 450px

    #carouselContainer {
        padding :4em 0 8em;

        &.markOrientest {
            padding-top : 8em;
            /*
            margin-top:0;
            padding-top:4em;
            padding-bottom:2em;
            */
            background-color: transparent;
        }

        #carousel {
            /*$colorLink: #33AD98;*/
            $colorLink: #356A73;

            width:90%;
            max-width: $largeurCarousel;
            height:auto;
            background-color: transparent;
            margin:0 auto;

            .VueCarousel-wrapper {
                border-radius:1em;
            }
            .VueCarousel-slide {
                img {
                    border-radius:1em;
                }
            }

            .VueCarousel-slide-active {
                img {/*
                    border-right: 2px solid white;
                    border-left: 2px solid white;*/
                    max-width: 100%;
                    border-radius:1em;
                }
            }

            button {
                cursor:pointer;
            }

            .VueCarousel-pagination {
                button {
                    &:focus {
                        outline:none;
                    }
                    &:hover, &:focus {
                        opacity:0.5;
                    }
                }
            }

            .tdb-carousel--prevbutton, .tdb-carousel--nextbutton {
                position:absolute;
                top:45%;
                background-color: transparent !important;
/*
                border-top : 12px solid transparent;
                border-bottom : 12px solid transparent;
*/
            }
            .tdb-carousel--prevbutton {
/*
                left:-6%;
                border-right : 16px solid $colorLink;
                border-left: none;
*/
                width:53px;
                height:53px;
                background: url("~@/assets/img/oe/carousel/boutonG.png") transparent no-repeat bottom left;
                &:hover, &:focus {
                    background-image: url("~@/assets/img/oe/carousel/boutonGh.png");
                }
            }
            .tdb-carousel--nextbutton {
/*
                right:-6%;
                border-left : 16px solid $colorLink;
                border-right: none;
*/
                width:53px;
                height:53px;
                background: url("~@/assets/img/oe/carousel/boutonD.png") transparent no-repeat bottom right;
                &:hover, &:focus {
                    background-image: url("~@/assets/img/oe/carousel/boutonDh.png");
                }
            }
            .tdb-carousel--stopplaybutton {
                position: absolute;
                left: calc(50% - 80px);
                background-color: transparent;
                bottom:0;
            }

            &.plusDeQuatre {
            .tdb-carousel--stopplaybutton {
                left: calc(50% - 100px);
                }
            }

            .tdb-carousel--stopplaybutton.play {
                display : inline-block;
                height : 0;
                width : 0;
                bottom: 0.25rem;
                border-top : 9px solid transparent;
                border-bottom : 9px solid transparent;
                border-left : 16px solid $colorLink;
                border-right: none;
            }
            .tdb-carousel--stopplaybutton.pause {
                bottom: 0.4rem;
                border-left: 5px solid $colorLink;
                border-right: 5px solid $colorLink;
                border-top: 0;
                border-bottom: 0;
                border-radius: 1px;
                padding: 0 4px;
                height: 16px;
            }

            .VueCarousel-dot-container, .VueCarousel-dot {
              margin-top: 0 !important;
            }
            .VueCarousel-dot {
                padding: 6px !important;
                width: 16px !important;
                height: 16px !important;
                background-color: transparent !important;
                }

            .VueCarousel-dot::after {
              height: 16px !important;
              width: 16px !important;
              content: '' !important;
              display: inline-block !important;
              border: 2px $colorLink solid !important;
              border-radius: 50% !important;
              box-sizing: border-box !important;
                visibility: visible;
            }
            .VueCarousel-dot.VueCarousel-dot--active {
              background-color: $colorLink !important;
              height: 16px !important;
              width: 16px !important;
            }
        }
    }


    .addeoLarge, .addeoLargeLow {
        #carousel {
            .tdb-carousel--prevbutton {
                left:-3%;
            }
            .tdb-carousel--nextbutton {
                right:-3%;
            }
        }
    }

    .addeoMedium:not(.addeoLargeLow) {
        #carousel {
            max-width: $largeurCarouselM;

            .tdb-carousel--prevbutton {
                left:-5%;
                @media screen and (max-width: 900px) {
                    /*left:-5%;*/
                }
            }
            .tdb-carousel--nextbutton {
                right:-5%;
                @media screen and (max-width: 900px) {
                    /*right:-5%;*/
                }
            }
        }
    }

    .addeoSmall {

        #carousel {
            width:100%;
            max-width: $largeurCarouselS;
/*
            .tdb-carousel--prevbutton, .tdb-carousel--nextbutton {
                display:none;
            }
*/
            .tdb-carousel--prevbutton {
                left:5%;
                @media screen and (max-width: 500px) {
                    left:-5%;
                }
            }
            .tdb-carousel--nextbutton {
                right:5%;
                @media screen and (max-width: 500px) {
                    right:-5%;
                }
            }
        }
    }
</style>
