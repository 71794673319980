<template>
    <div id="footerInfos" class="itemFooter">
        <p class="centrer">
            <span v-if="markOrientest" class="std">
                <a href="/" class="" title="Accueil" rel="noopener" >
                    <img src="../../assets/img/oe/logoOrientEst.svg" alt="OrientEst" class="centrer logoLorfolio" />
                </a>
            </span>
            <span v-if="!markOrientest" class="std">
                <a href="/" class="" title="Accueil Lorfolio" rel="noopener" v-if="!$parent.$parent.$parent.sitePRO">
                    <img src="../../assets/img/footer/logoLorfolio.svg" alt="Lorfolio" class="centrer logoLorfolio" />
                </a>
                <a href="https://www.lorfolio.fr" target="_blank" rel="noopener" class="" title="Accueil Lorfolio" v-if="$parent.$parent.$parent.sitePRO">
                    <img src="../../assets/img/footer/logoLorfolio.svg" alt="Lorfolio" class="centrer logoLorfolio" />
                </a>
            </span>
            <span class="">est un service proposé par</span>
            <a v-if="markOrientest" href="https://www.grandest.fr" target="_blank" rel="noopener" class="" title="Région Grand Est">
                <img :src="logoRGE" alt="Région Grand Est" class="centrer logoGE" v-bind:class="{logoRGE : $router.options.paramNouveauLogo}" />
            </a>
            <span class="partenariat" v-if="markOrientest">en partenariat avec <br />
                <router-link :to="{ name: 'Partenaires'}" class="lienSignataires">les signataires de la charte<br />du Service Public Régional de l'Orientation</router-link>
            </span>
            <a v-if="!markOrientest" href="https://www.grandest.fr" target="_blank" rel="noopener" class="" title="Région Grand Est">
                <img src="../../assets/img/footer/logoGrandE.svg" alt="Région Grand Est" class="centrer logoGE" />
            </a>
            <span  v-if="!markOrientest">en partenariat avec <br />
                <router-link :to="{ name: 'Partenaires'}" class="lienSignataires">les signataires de la charte<br />du Service Public Régional de l'Orientation</router-link>
            </span>
        </p>
  </div>
</template>


<script>
export default {
    name: 'FooterInfos',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed:{
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        sitePRO() {
            return(this.$router.options.paramPRO);
        },
        logoRGE() {
            if(this.$router.options.paramNouveauLogo) {
                return require("../../assets/img/header/logoRGE.svg");
            }
            else {
                return require("../../assets/img/header/logoGrandE.svg");
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .markOrientest {
        #footerInfos {
            background-color: transparent;
            p {
                margin:0;
                padding:0;
                font-size:1em;

                .logoLorfolio {
                    max-width:260px;
                }
                .logoGE {
                    max-width:140px;
                    &.logoRGE {
                        max-width:180px;
                    }
                }
                a {
                    color:#006EAB;
                }
                span  {
                    margin: 0 0 0.6em;
                    &.partenariat {
                        margin-top:0.2em;
                    }
                }
            }
        }
    }

    #footerInfos {
        background-color: transparent;
        p {
            margin:0;
            padding:0;
            font-size:0.9em;

            .logoLorfolio {
                max-width:260px;
            }
            .logoGE {
                max-width:120px;
            }
            span {
                width:100%;
                margin:1em auto;
                &.std {
                    margin:0 0;
                }
            }
            span, img {
                display:inline-block;
            }

            a {
                color:white;

                &.lienSignataires {
                    text-decoration: underline;
                    /*font-weight: 600;*/
                }
            }
        }
    }
</style>
