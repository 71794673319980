<template>
    <div id="footerMenu" class="itemFooter">
        <div class="container" v-if="markOrientest">
            <div class="menuTitre">À propos</div>
            <ul class="menuListe" v-if="!$parent.$parent.$parent.sitePRO">
                <li><a target="_blank" title="Webfolios Grand Est" :href="urlWebfolios">Les Webfolios</a></li>
                <li><router-link to="/partenaires">Partenaires</router-link></li>
                <li><router-link to="/mentions-legales">Mentions légales</router-link></li>
                <li><router-link :to="{ name: 'Contact', params: { pere: 'footer' }}">Contact</router-link></li>
            </ul>
            <ul class="menuListe" v-if="$parent.$parent.$parent.sitePRO">
                <li><router-link to="/rencontres-pro">Rencontres Orient’Est PRO</router-link></li>
                <li><router-link to="/partenaires-pro">Partenaires</router-link></li>
                <li><router-link to="/mentions-legales-pro">Mentions légales</router-link></li>
                <li><router-link :to="{ name: 'ContactPro', params: { pere: 'footerPro' }}">Contact</router-link></li>
            </ul>
        </div>
        <div class="container" v-if="!markOrientest">
            <div class="menuTitre">À propos</div>
            <ul class="menuListe" v-if="!$parent.$parent.$parent.sitePRO">
                <li><a target="_blank" title="Webfolios Grand Est" href="http://webfolios.lorfolio.fr/">Les Webfolios</a></li>
                <li><router-link to="/partenaires">Partenaires</router-link></li>
                <li><router-link to="/mentions-legales">Mentions légales</router-link></li>
                <li><router-link :to="{ name: 'Contact', params: { pere: 'footer' }}">Contact</router-link></li>
            </ul>
            <ul class="menuListe" v-if="$parent.$parent.$parent.sitePRO">
                <li><router-link to="/rencontres-pro">Rencontres Lorfolio Pro</router-link></li>
                <li><router-link to="/partenaires-pro">Partenaires</router-link></li>
                <li><router-link to="/mentions-legales-pro">Mentions légales</router-link></li>
                <li><router-link :to="{ name: 'ContactPro', params: { pere: 'footerPro' }}">Contact</router-link></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterMenu',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        laRoute() {
            return(this.$route.name);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        urlWebfolios() {
            var urlFolios = "https://webfolios.grandtest.addeo.ovh";
            if (this.$parent.$parent.$parent.isPreProdApp) {
                urlFolios =  "https://preprod.webfolios.orientest.fr";
            }
            else {
                if (this.$parent.$parent.$parent.isProdApp) {
                    urlFolios =  "https://webfolios.orientest.fr";
                }
            }
            return(urlFolios);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .addeoSmall {
        #footerMenu {
            .container {
                .menuListe {
                    padding-left: 0;
                }
            }
        }
    }


    .pro {
        #footerMenu {
            .container {
                max-width: 220px;
            }
        }
    }

    #footerMenu {
        text-transform: uppercase;
        background-color: transparent;
        text-align: center;

        .container {
            text-align: left;
            margin:0 auto;
            width:100%;
            max-width: 150px;

            .menuTitre {
                color:#33AD98;
                margin-bottom:1em;
                font-size:120%;
                font-weight: bold;
            }

            .menuListe {
                font-size:80%;
                list-style-type:none;
                padding-left: 1em;
                li {
                    margin-top:0.6em;

                    a {
                        color:white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
</style>
