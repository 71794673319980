<template>
    <div id="footerMenu" class="itemFooter">
        <div class="container" v-if="markOrientest">
            <div class="sousContainer">
                <div class="menuTitre">Accès rapide</div>
                <ul class="menuListe marquerLiensExternes" >
                    <li v-if="0 && $parent.$parent.$parent.sitePRO"><router-link to="/rencontres-pro">Rencontres Orient’Est PRO</router-link></li>
                    <li><a target="_blank" title="Orient'Est" :href="urlOrientest">Orient'Est</a></li>
                    <li v-if="$parent.$parent.$parent.sitePRO"><a target="_blank" title="Mon Orient'Est" :href="urlMonOrientest">Mon Orient'Est</a></li>
                    <li><a target="_blank" title="Agenda de l'orientation" :href="urlAgenda">Agenda de l'orientation</a></li>
                    <li><a target="_blank" title="Annuaire de l'orientation" :href="urlAnnuaire">Annuaire de l'orientation</a></li>
                    <li><a target="_blank" title="Ressources" :href="urlRessources">Ressources</a></li>
                    <li><a target="_blank" title="Actualités" :href="urlActualites">Actualités</a></li>
                    <li><a target="_blank" title="Webfolios" :href="urlWebfolios">Webfolios</a></li>
                </ul>
            </div>


            <div class="sousContainer">
                <div class="menuTitre">À propos</div>
                <ul class="menuListe marquerLiensExternes" v-if="!$parent.$parent.$parent.sitePRO">
                    <li v-if="0"><a target="_blank" title="Webfolios Grand Est" :href="urlWebfolios">Les Webfolios</a></li>
                    <li><router-link to="/partenaires">Partenaires</router-link></li>
                    <li><router-link to="/mentions-legales">Mentions légales</router-link></li>
                    <li><router-link :to="{ name: 'Contact', params: { pere: 'footer' }}">Contact</router-link></li>
                </ul>

                <ul class="menuListe marquerLiensExternes" v-if="$parent.$parent.$parent.sitePRO">
                    <li><router-link to="/partenaires-pro">Partenaires</router-link></li>
                    <li><router-link to="/mentions-legales-pro">Mentions légales</router-link></li>
                    <li><router-link :to="{ name: 'ContactPro', params: { pere: 'footerPro' }}">Contact</router-link></li>
                </ul>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    name: 'FooterMenu',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        laRoute() {
            return(this.$route.name);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        urlWebfolios() {
            var urlFolios = "https://webfolios.grandtest.addeo.ovh";
            if (this.$parent.$parent.$parent.isPreProdApp) {
                urlFolios =  "https://preprod.webfolios.orientest.fr";
            }
            else {
                if (this.$parent.$parent.$parent.isProdApp) {
                    urlFolios =  "https://webfolios.orientest.fr";
                }
            }
            return(urlFolios);
        },
        urlOrientest() {
            var lUrl = "https://preprod.portail.orientest.fr/";
            if (this.$parent.$parent.$parent.isPreProdApp) {
                lUrl =  "https://preprod.portail.orientest.fr/";
            }
            else {
                if (this.$parent.$parent.$parent.isProdApp) {
                    lUrl =  "https://orientest.fr";
                }
            }
            return(lUrl);
        },
        urlMonOrientest() {
            var lUrl = "https://portailmonorientest.grandtest.addeo.ovh";
            if (this.$parent.$parent.$parent.isPreProdApp) {
                lUrl =  "https://preprod.portail.monorientest.fr";
            }
            else {
                if (this.$parent.$parent.$parent.isProdApp) {
                    lUrl =  "https://www.monorientest.fr";
                }
            }
            return(lUrl);
        },
        urlAgenda() {
            var lUrl = "https://grandtest.addeo.ovh/agenda/index_public";
            if (this.$parent.$parent.$parent.isPreProdApp) {
                lUrl =  "https://preprod.agenda.orientest.fr";
            }
            else {
                if (this.$parent.$parent.$parent.isProdApp) {
                    lUrl =  "https://agenda.orientest.fr";
                }
            }
            return(lUrl);
        },
        urlAnnuaire() {
            var lUrl = "https://grandtest.addeo.ovh/asstructures/index";
            if (this.$parent.$parent.$parent.isPreProdApp) {
                lUrl =  "https://preprod.annuaire.orientest.fr";
            }
            else {
                if (this.$parent.$parent.$parent.isProdApp) {
                    lUrl =  "https://annuaire.orientest.fr";
                }
            }
            return(lUrl);
        },
        urlRessources() {
            var lUrl = "https://preprod.portail.orientest.fr/ressources-metier";
            if (this.$parent.$parent.$parent.isPreProdApp) {
                lUrl =  "https://preprod.portail.orientest.fr/ressources-metier";
            }
            else {
                if (this.$parent.$parent.$parent.isProdApp) {
                    lUrl =  "https://orientest.fr/ressources-metier";
                }
            }
            return(lUrl);
        },
        urlActualites() {
            var lUrl = "https://preprod.portail.orientest.fr/actualites-orient-est";
            if (this.$parent.$parent.$parent.isPreProdApp) {
                lUrl =  "https://preprod.portail.orientest.fr/actualites-orient-est";
            }
            else {
                if (this.$parent.$parent.$parent.isProdApp) {
                    lUrl =  "https://orientest.fr/actualites-orient-est";
                }
            }
            return(lUrl);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .addeoSmall {
        #footer {
            #footerMenu {
                .container {
                    .menuListe {
                        padding-left: 0;
                        li {
                            display: block;
                            margin-top:0;
                            a {
                                display: inline-block;
                                padding: 8px 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .addeoSmall, .addeoMedium:not(.addeoLargeLow) {
        #footer {
            #footerMenu {
                display: block;
                float:none;
                margin:4em auto;
                padding:0;
            }
        }
    }

    #footer {

		#footerMenu {
            .container {
                padding:0;
            }
			.menuTitre {
				font-size: 26px;
				font-family: Dosis-Bold, sans-serif;
                margin: 0 0 20px;
                line-height: 1;
				&::after {
					height: 2px;
                    background: #006EA9;
                    width: 50px;
                    margin-top: 10px;
                    content: "";
                    display: block;
				}
			}

			.menuTitre, .menuListe {
                text-align: left;
				text-transform: none;
				font-weight: bold;
			}
			.container {
                .sousContainer {
                    ul.menuListe {
                        list-style: none;
                        line-height: 1.6;
                        li {
                            margin-bottom: 8px;
                            padding-bottom: 0;
                            a {
                                padding: 2px 0;
                                color: #006EAB;
                                font-size: 16px;
                                &:hover {
                                    color: #006EAB;
                                    text-decoration: underline;
                                }
                                &[href*="http"].marquerLiensExternes::after {
                                    width: 8px;
                                }
                            }
                        }
                    }
                }
			}
		}

/*
        #footerMenu {
            float: right;
            text-transform: uppercase;
            background-color: transparent;
            text-align: center;
            padding-left:10%;
            width:40%;

            .container {
                text-align: left;
                margin:0 auto;
                width:100%;
                max-width: 250px;
                padding:0;

                .menuTitre {
                    font-family: Dosis, Roboto, sans-serif;
                    display: block;
                    margin-bottom:2em;
                    font-size:110%;
                    font-weight: bold;
                    &:after, &::after {
                        content: " ";
                        border-bottom: 3px solid #006EAB;
                        height: 5px;
                        width: 60px;
                        position: absolute;
                        left: 0;
                        bottom: -10px;
                    }
                }

                .menuListe {
                    font-family: Oxygen, Roboto, sans-serif;
                    font-size:90%;
                    list-style-type:none;
                    padding-left: 1em;
                    &:first-of-type {
                        margin-bottom:3em;
                    }
                    &:lasst-of-type {
                        margin-bottom:0;
                    }
                    li {
                        margin-top:0.6em;

                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
*/
    }



</style>
