<template>
  <div id="footer" v-bind:class="{markOrientest : markOrientest}">
      <div id="containerFooter">
        <footer-infos v-if="!markOrientest" />
        <div id="sousContainerFooter" v-if="markOrientest">
            <footer-infos/>
            <footer-social-oe v-if="markOrientest" />
        </div>
        <footer-menu-oe v-if="markOrientest" />
        <footer-menu v-if="!markOrientest" />
        <footer-social v-if="!markOrientest" />
        <div v-if="addeoSmall && isAccueil" id="barre" class="clear"></div>
        <div class="clear"></div>
        <bloc-connexion v-if="addeoSmall && isAccueil" :header="false" />
      </div>
  </div>
</template>

<script>

import FooterInfos from '@/components/footer/FooterInfos.vue'
import FooterMenu from '@/components/footer/FooterMenu.vue'
import FooterMenuOE from '@/components/footer/FooterMenuOE.vue'
import FooterSocial from '@/components/footer/FooterSocial.vue'
import FooterSocialOE from '@/components/footer/FooterSocialOE.vue'
import Connexion from '@/components/Connexion.vue'

export default {
    name: 'Footer',
    props: {
    },
    components: {
        'footer-infos': FooterInfos,
        'footer-menu': FooterMenu,
        'footer-menu-oe': FooterMenuOE,
        'footer-social': FooterSocial,
        'footer-social-oe': FooterSocialOE,
        'bloc-connexion': Connexion,
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        sitePro() {
            return(this.$parent.$parent.sitePRO);
        },
        isAccueil() {
            if(this.$parent.accueil) {
                return(true);
            }
            return(false);
        },
        addeoSmall() {
            return(this.$parent.addeoSmall);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
    #footer {
        /*margin-top:4em;*/
        width:100%;
        background-color: #4B3C8F;
        border-top: 5px solid #33AD98;
        padding: 3em 0 2em;

        &.markOrientest {
            background-color: #EDF5F9;
            border-top: 5px solid #006EAB;
            /*padding: 3em 0 2em;*/
            font-family: Oxygen, Roboto, Arial, sans-serif;
            padding: 0.6em 0 3em;

            a {
                /*color: #006EAB;*/
                color: #006EA9; /* cohérence orientEst.fr */
            }
            .itemFooter {
                /*font-size: 1.4em;*/
            }
            #barre {
                background: no-repeat center center transparent url("~@/assets/img/oe/barre.svg");
                }

            ul.marquerLiensExternes li a[href*="http"]::after {
              content: url("~@/assets/img/oe/out.svg");
              height: auto;
              display: inline-block;
              width: 8px;
              margin-left: 4px;
              vertical-align: super;
              vertical-align: top;
              margin-top: -4px;
            }
        }

        #containerFooter {
            /*max-width: 800px;*/
            margin:2em auto 0;
            width: 100%;

            #footerSocial { /* à l'occasion d'OrientEst mais pour tout */
                margin-top: 1em;
                float:left;
            }
        }


        #barre {
            background: no-repeat center center transparent url("~@/assets/img/footer/barre.svg");
            min-height: 5em;
            width:90%;
            max-width: 800px;
            margin:0 auto;
            opacity: 0.5;
        }
        &:not(.markOrientest) {
            .itemFooter {
                color:white;
                a {
                    &:hover {
                        color:white;
                    }
                }
            }
        }
        #footerMenu, #footerSocial {
            width:50%;
        }
        .itemFooter {
            display:inline-block;
            max-width: 400px;
            vertical-align: top;
            a.lienSignataires {
                &:hover, &:focus {
                    text-decoration: none;
                }
            }
            .social {
                a {
                    &:hover, &:focus {
                        opacity:0.5;
                    }
                }
            }
        }
    }

    .addeoMedium:not(.addeoLargeLow) {
        #footer {
            padding-left: 5%;
            padding-right: 5%;
            width:90%;

            .itemFooter {
                width:42.5%;
                max-width: 100%;


                &#footerSocial {
                    margin:0 5% 2em 5%; /* à l'occasion d'OrientEst mais pour tout */
                }

            }

            &:not(.markOrientest) {
                .itemFooter {
                    &:first-of-type {
                        margin-right: 5%;
                    }
                }
            }

            @media screen and (max-width: 800px) {
                padding-left: 2.5%;
                padding-right: 2.5%;
                width:95%;

                &:not(.markOrientest) {
                    .itemFooter {
                        &:first-of-type {
                            margin-right: 10%;
                        }
                    }
                }
            }
        }
    }

    .addeoSmall {
        #footer {
            width:100%;
            #containerFooter {
                .itemFooter {
                    padding-left: 2.5%;
                    padding-right: 2.5%;
                    width:95%;
                    &#footerSocial {
                        float:none; /* à l'occasion d'OrientEst mais pour tout */
                    }
                }
            }

            &:not(.markOrientest) {
                #containerFooter {
                    .itemFooter {
                        margin-bottom:2em;
                    }
                }
            }
        }
    }



html body #app #footer * {
    box-sizing: border-box;
}

.addeoMedium, .addeoLarge  {
    @media (min-width: 992px) {
        #footer {
            #containerFooter {
                width: 100%;
                padding: 0 15px;
                max-width: 970px;

                @media (min-width: 1200px) {
                    max-width: 1170px;
                }

                #sousContainerFooter, #footerMenu {
                  float: left;
                }
                #sousContainerFooter {
                    max-width: 700px;
                    padding: 0 0 0 2%;
                    width: 50%;
                }
                #footerMenu {
                    width: 44%;
                    max-width: 460px;
                    padding: 0 2% 0 0;
                    .container {
                        height:100%;
                        .sousContainer {
                            float:right;
                            min-width:151px;
                        }
                        .sousContainer:first-of-type {
                            float:left;
                            min-width:180px;
                            padding-right:40px;
                        }
                    }
                }
            }
        }
    }
}

.addeoMedium, .addeoSmall {
    @media (max-width: 991px) {
        #footer {
            #containerFooter {
                float: none;

				#footerMenu {
                    margin-top: 75px;
					width:80%;
					max-width:550px;
					.container {
						height:100%;
						.sousContainer {
							margin-top: 0;
							padding: 0;
							float:right;
							min-width:160px;
						}
                            @media (min-width: 624px) {
                            .sousContainer:first-of-type {
                                float:left;
                                min-width:190px;
                                padding: 0 60px 0 60px;
                            }
                        }
					}
				}
                @media (max-width: 623px) {
                    #footerMenu {
                        max-width: 90%;
                        margin: 0 1% 0 9%;
                        .container {
                            .sousContainer {
                                width: 100%;
                                float:left;
                                &:last-of-type {
                                    margin-top: 2em;
                                    padding: 10px 0;
                                }
                            }
                        }
                    }
                }
			}
		}
	}
}
/*
	&.addeoSmall {
		#sp-footer {
			#sp-footer3 {
				max-width: 90%;
				margin: 0 1% 0 9%;
				.sp-column {
					.sp-module {
						.sp-module-title {
							margin-bottom: 1em;
						}
						ul.menuFooter > li > a {
							padding: 0 0 8px 0;
						}
					}
				}
			}
		}
	}
*/

</style>
