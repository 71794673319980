<template>
    <div class="containerPublicI">

<!-- <transition name="fade">
        <div class="publicI" v-if="ready"> -->
        <div class="publicI">

<transition name="scale-fade">
<span v-if="nbBulles">
            <img  class="fond survol derriere" src="../../../assets/img/public/rondexter.svg" alt="" />
            <img  class="illustration" :src="getImgUrl" alt="" />
            <img  class="ombre" :src="getOmbreUrl" :alt="data.itemsTxt[item]" />
            <span class="legende" v-if="data.items[item]">
                <span v-html="data.itemsTxt[item]"></span>
            </span>
            <img   class="fond toujours" src="../../../assets/img/public/rondintermauve.svg" alt="" />
            <img  class="dessus" src="../../../assets/img/public/rondferme.svg" alt="" />
</span>
</transition>


<transition name="slideX-fade">
            <span class="bulle gauche" v-if="nbBulles == 1" >
                <span class="seul"  >
                    <img   class="" :src="data.imgsGauche[2].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][0]"></span>
                </span>
            </span>
</transition>
<transition name="slideX-fade">
            <span class="bulle gauche" v-if="nbBulles == 2" >
                <span class="seul" >
                    <img   class="" :src="data.imgsGauche[1].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][0]"></span>
                </span>
            </span>
</transition>
<transition name="slideMoinsX-fade">
            <span class="bulle droite" v-if="nbBulles == 2" >
                <span class="seul" >
                    <img   class="" :src="data.imgsGauche[3].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][1]"></span>
                </span>
            </span>
</transition>
<transition name="slideX-fade">
            <span class="bulle gauche" v-if="nbBulles == 3" >
                <span class="plusHaut" >
                    <img   class="" :src="data.imgsGauche[1].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][0]"></span>
                </span>
                <span class="plusBas" >
                    <img   class="gauche3" src="/img/publics/bulles/gauche2.svg" alt="" />
                    <span class="txt txt3" v-html="data.itemsCont[item][1]"></span>
                </span>
            </span>
</transition>
<transition name="slideMoinsX-fade">
            <span class="bulle droite" v-if="nbBulles == 3" >
                <span class="seul" >
                    <img   class="" src="/img/publics/bulles/droite2.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][2]"></span>
                </span>
            </span>
</transition>
<transition name="slideX-fade">
            <span class="bulle gauche" v-if="nbBulles == 4" >
                <span class="plusHaut" >
                    <img   class="" :src="data.imgsGauche[1].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][0]"></span>
                </span>
                <span class="plusBas" >
                    <img   class="gauche3" src="/img/publics/bulles/gauche2.svg" alt="" />
                    <span class="txt txt3" v-html="data.itemsCont[item][1]"></span>
                </span>
            </span>
</transition>
<transition name="slideMoinsX-fade">
            <span class="bulle droite" v-if="nbBulles == 4" >
                <span class="plusHaut" >
                    <img   class="" src="/img/publics/bulles/droite1.svg" alt="" />
                    <span class="txt txt3" v-html="data.itemsCont[item][2]"></span>
                </span>
                <span class="plusBas"  >
                    <img   class="" src="/img/publics/bulles/droite3.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][3]"></span>
                </span>
            </span>
</transition>
<transition name="slideX-fade">
            <span class="bulle gauche" v-if="nbBulles == 5" >
                <span class="haut" >
                    <img   class="" :src="data.imgsGauche[1].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][0]"></span>
                </span>
                <span class="milieu" >
                    <img   class="" src="/img/publics/bulles/gauche2.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][1]"></span>
                </span>
                <span class="bas" >
                    <img   class="" :src="data.imgsGauche[3].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][2]"></span>
                </span>
            </span>
</transition>
<transition name="slideMoinsX-fade">
            <span class="bulle droite" v-if="nbBulles == 5" >
                <span class="plusHaut" >
                    <img   class="" src="/img/publics/bulles/droite1.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][3]"></span>
                </span>
                <span class="plusBas"  >
                    <img   class="" src="/img/publics/bulles/droite3.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][4]"></span>
                </span>
            </span>
</transition>
 <transition name="slideX-fade">
            <span class="bulle gauche" v-if="nbBulles == 6" >
                <span class="haut" >
                    <img   class="" :src="data.imgsGauche[1].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][0]"></span>
                </span>
                <span class="milieu" >
                    <img   class="" :src="data.imgsGauche[2].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][1]"></span>
                </span>
                <span class="bas" >
                    <img   class="" :src="data.imgsGauche[3].src" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][2]"></span>
                </span>
            </span>
</transition>
 <transition name="slideMoinsX-fade">
<!-- <div class="toto"> -->
            <span class="bulle droite" v-if="nbBulles == 6" >
                <span class="haut" >
                    <img   class="" src="/img/publics/bulles/droite1.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][3]"></span>
                </span>
                <span class="milieu" >
                    <img   class="" src="/img/publics/bulles/droite2.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][4]"></span>
                </span>
                <span class="bas" >
                    <img   class="" src="/img/publics/bulles/droite3.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][5]"></span>
                </span>
            </span>
<!-- </div>    -->
</transition>
        </div>

<!-- </transition> -->
    </div>
</template>

<script>
export default {
    name: 'Public',
        props: {
            data: {
                type: Object
            },
            item: {
                type: String
            }
        },
    data() {
        return {
            ready:1,
            myTimeout:null,
            tmp:true
        }
    },
    created() {
    },

    mounted: function () {
    },
    watch: {
        item(newVal) {
            this.ready=0;
            this.myTimeout = setTimeout(this.cPret, 250);

        }
    },
    computed: {
        nbBulles() {
            if (!this.ready || (typeof this.data.itemsCont[this.item] === "undefined")) {
                return(0);
            }
            return(this.data.itemsCont[this.item].length)
        },
        getImgUrl() {
            var image = "/img/publics/mev-"+this.data.images[this.item]+".svg";
            return  image
        },
        getOmbreUrl() {
            var image = "/img/publics/ombre-"+this.data.images[this.item]+".png";

            return  image
        }
    },

    methods: {
        cPret() {
            this.ready=1;
            if (this.myTimeout) {
                clearTimeout(this.myTimeout);
            }
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.addeoMedium #publics #itemCourant {

    .containerPublicI {
        width:100%;
        margin:4em auto 1em ;

        .publicI {
            max-width:230px !important;

            .legende {
                font-size:1.1em ;

                span {
                    font-size:1.1em ;
                }
            }

            span.bulle {
                width: 230px;
                top:0;
                &.gauche {
                    span.txt {
                        @media screen and (min-width: 800px) {
                            padding-left: 4%;
                        }
                    }
                }
                span.txt {
                    font-size:2vw ;
                    @media screen and (min-width: 800px) {
                        font-size:0.85em ;
                        max-width: 78%;
                        width: 78%;
                    }
                }

                &.gauche {
                    .txt3 {
                        line-height: 1.1em;
                    }
                }
            }

            span.legende {
                span {
                    /*line-height: 1.5;*/
                    line-height: 1.3;
                    /*font-size:1em ;*/
                    /*
                    font-size:2.1vw ;
                    @media screen and (min-width: 900px) {
                        font-size:1em ;
                    }
                    */
                }
            }
        }

    }
}

    .addeoLarge {
        #publics #itemCourant {

            .containerPublicI {

                .publicI {

                    .illustration, .fond, .legende {
                    }

                    span.legende {
                        font-size:2em;
                    }
                }
            }
        }
    }

#publics #itemCourant {



    .containerPublicI {
        width:100%;

        .publicI {
            width:50% !important;
            max-width:400px !important;
            margin:0 auto;

            .illustration, .fond, .legende {
                cursor:pointer;
            }


            span.legende {

                span {
                        line-height: 1.3;
                    }
                }

            span.important {
                color:#FFD401;
            }

            img {
                z-index: 12;
                position: relative;
            }

            .fond.toujours {
                display:block !important;
                opacity:0.4;
            }

            .bulle {
                position:absolute;
                width: 300px;
                height: auto;
                top:15%;
                /*
                top: 50%;
                transform: translateX(0) translateY(-50%);
                */

                &.gauche {
                    right:90%;
                    span.txt {
                        padding-left: 7.5%;
                        padding-right: 17.5%;
                        text-align: left;
                        }
                    .gauche3 {
                        transform: scaleX(1) scaleY(-1);
                    }

                }
                &.droite {
                    left:90%;
                    span.txt {
                        padding-left: 20%;
                        text-align: left;
                        }
                }
                > span {
                    width: 100%;
                    display: inline-block;
                }
                .haut {
                    transform: translateY(-30%);
                }
                .plusHaut {
                    transform: translateY(15%);
                }
                .milieu {
                    transform: translateY(-10%);
                }
                .plusBas {
                    transform: translateY(50%);
                }
                .bas {
                    transform: translateY(10%);
                }
                .seul {
                    transform: translateY(100%);
                }

                img {
                    width:100%;
                    z-index: 20;

                }
                span.txt {
                    z-index: 21;
                    margin: 0;
                    padding: 0 5%;
                    display: inline-block;
                    position: absolute;
                    text-align: center;
                    max-width: 75%;
                    width: 75%;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
        }
    }

.scale-fade-enter-active {
  transition: all 1s ease;
}
.scale-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.scale-fade-enter, .scale-fade-leave-to {
    opacity: 0;
    transform: scale(0);
}

}

                /* Enter and leave animations can use different */
/* durations and timing functions.              */
.slideX-fade-enter-active {
  transition: all .3s ease;
}
.slideX-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slideX-fade-enter, .slideX-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    /*transform: scale(0);*/
  transform: translateX(30%) ;
  opacity: 0;
}
    /* durations and timing functions.              */
.slideMoinsX-fade-enter-active {
  transition: all .4s ease;
}
.slideMoinsX-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slideMoinsX-fade-enter, .slideMoinsX-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    /*transform: scale(0);*/
  transform: translateX(-30%) ;
  opacity: 0;
}
</style>
