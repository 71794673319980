<template>
  <div id="footerSocial" class="itemFooter">
    <ul class="social">
        <li><a href="https://www.facebook.com/orientationgrandest " target="_blank" rel="noopener" class="facebook" title="Facebook Orient'Est"> <span class="fa fa-facebook" aria-hidden="true"></span> </a></li>
        <li><a href="https://twitter.com/orient_form_GE" target="_blank" rel="noopener" class="twitter" title="Twitter Grand Est"> <span class="fa fa-twitter" aria-hidden="true"></span> </a></li>
        <li><a href="https://bit.ly/YoutubeOrientation" target="_blank" rel="noopener" class="youtube" title="Chaîne Youtube Orient'Est"> <span class="fa fa-youtube" aria-hidden="true"></span> </a></li>
        <li v-if="!$parent.$parent.$parent.sitePRO"><a href="https://www.linkedin.com/in/orientation-formation-grand-est" target="_blank" rel="noopener" class="linkedin" title="Linkedin Grand Est"> <span class="fa fa-linkedin" aria-hidden="true"></span> </a></li>
        <li v-if="$parent.$parent.$parent.sitePRO"><a href="https://www.linkedin.com/groups/8972559" target="_blank" rel="noopener" class="linkedin" title="Linkedin Grand Est"> <span class="fa fa-linkedin" aria-hidden="true"></span> </a></li>
        <li><a href="https://www.instagram.com/odmgrandest/" target="_blank" rel="noopener" class="instagram" title="Instagram olympiades des metiers Grand Est"> <span class="fa fa-instagram" aria-hidden="true"></span> </a></li>
    </ul>



  </div>
</template>

<script>
export default {
    name: 'FooterSocial',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    div#footerSocial {
        &.itemFooter {
            background-color: transparent;
            vertical-align: top;

            ul.social {
                padding:0;
                margin:0;

                li {
                    display:inline-block;
                    margin : 0 0.4em 1em;

                    a span {
                        font-size:1.4em;
                        min-width:1.6em;
                        min-height:1.6em;
                        color: #4B3C8F;
                        background-color: #35A89B;
                        border-radius: 2em;

                        &:before {
                            line-height: 1.6em;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
</style>
