<template>
    <div id="reseau">
    </div>
</template>

<script>
export default {
    name: 'Usages',
    components: {
        },
    props: {
    },
    data() {
        return {
            tmp:true
        }
    },

    computed: {
    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
    #reseau {
        width:100%;
        height:auto;
        background-color: transparent;

        .contenu {
            span {
                /*
                width: 40ch;
                display: inline-block;
                */
            }
        }

    }
</style>
