<template>
    <div id="conteneurHeaderLogosPro" v-bind:class="{markOrientest : markOrientest, pasOrientest : !markOrientest}">
        <div id="motsCroises"  v-if="markOrientest"></div>
        <div class="headerOrientest" v-if="markOrientest">
            <div class="illustration"></div>
            <div id="logoLorfolio" class="itemLogo dessus">
                <a href="/" class="" title="Orientest Pro" v-if="markOrientest">
                    <img src="../../../assets/img/pro/header/logoOrientestPro.svg" alt="OrientEst Pro" class="centrer" />
                </a>
                <h1 id="facilite"  class="dessus">
                    <span v-if="markOrientest">
                        Outiller vos pratiques
                    </span>
                </h1>
            </div>
            <div class="bas"></div>
        </div>
        <div id="headerLogosPro" v-if="!markOrientest">
            <img v-if="!addeoSmall" src="../../../assets/img/pro/header/evolution-emploi.png" alt="évolution, orientation, insertion, formation, emploi" class="nombril dessous" />
            <div id="logoLorfolio" class="itemLogo dessus">

                <a href="/" class="" title="Lorfolio" v-if="!markOrientest">
                    <img src="../../../assets/img/pro/header/logoLorfolioPro.svg" alt="Lorfolio" class="centrer" />
                </a>
            </div>
            <div v-if="addeoLarge" id="logoGE" class="itemLogo dessus">
                <a href="https://www.grandest.fr" target="_blank" rel="noopener" class="" title="Région Grand Est">
                    <img src="../../../assets/img/header/logoGrandE.svg" alt="Région Grand Est" class="centrer" />
                </a>
            </div>
            <h1 id="facilite"  class="dessus">
                <span v-if="!markOrientest">
                    Faciliter les choix professionnels
                </span>
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderLogos',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed:{
        addeoSmall() {
            return(this.$parent.$parent.$parent.addeoSmall);
        },
        addeoMedium() {
            return(this.$parent.$parent.$parent.addeoMedium);
        },
        addeoLarge() {
            return(this.$parent.$parent.$parent.addeoLarge);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #app.pro {
        #conteneurHeaderLogosPro.pasOrientest {
            background: no-repeat center bottom transparent url("~@/assets/img/pro/header/bas.png");
        }
        #header {
            background-color: #F3C935;
            background-image: none;

            &.markOrientest {
                /*padding-top: 80px;*/ /* pour connexion en fixed */
            }

            .gauche {
                background: no-repeat left bottom transparent url("~@/assets/img/pro/header/perso-gauche.png");
                width: 50%;

                @media screen and (max-width: 1750px) {
                    background: no-repeat left bottom transparent url("~@/assets/img/pro/header/perso-gauche-petits.png");
                }
/*
                @media screen and (max-width: 1550px) {
                    background-position: right bottom;
                    width: 40%;
                }
*/
                @media screen and (max-width: 1250px) {
                    background-position: right bottom;
                    width: 35%;
                }
            }
            .droite {
                background: no-repeat right bottom transparent url("~@/assets/img/pro/header/perso-droite.png");
                width: 50%;

                @media screen and (max-width: 1750px) {
                    background: no-repeat right bottom transparent url("~@/assets/img/pro/header/perso-droite-petits.png");
                    width: 50%;
                }
/*
                @media screen and (max-width: 1550px) {
                    background-position: left bottom;
                    width: 32%;
                }
*/
                @media screen and (max-width: 1250px) {
                    background-position: left bottom;
                    width: 30%;
                }
            }

            .nombril {
                @media screen and (max-width: 1550px) {
                    max-width:720px;
                    top:5%;
                }
            }
        }
        &.addeoMedium:not(.pro) {
            &:not(.addeoLargeLow) {
                #header {
                    .gauche {
                            background-position: right bottom;
                            background-size: contain;
                    }
                    .droite {
                            background-position: left bottom;
                            background-size: contain;
                    }
                    &.markOrientest {
                        #headerLogosPro {
                            min-height: 400px;
                            .nombril {
                                top: 25px;
                                width: 50%;
                                min-width: 380px;
                                /*
                                width: 50%;
                                */
                            }
                        }

                            div.gauche {
                                width: 35%;
                                background-size:cover;
                            }
                            div.droite {
                                width: 30%;
                                background-size:cover;
                            }

                        @media screen and (max-width: 860px) {

                            #headerLogosPro {
                                .nombril {
                                    top: 50px;
                                    }
                            }
                        }
                    }
                }

            }
        }

        &.addeoSmall {
            #header.markOrientest {
                .gauche {
                    @media screen and (max-width: 390px) {
                        display:none;
                    }
                }
            }
        }

    }
.addeoMedium {
        #header {
            #headerLogosPro {
                text-align: center; /*2021/07 */
                min-height: 490px;
                h1 {
                    margin:0 auto; /*2021/07 */
                    /*margin:0; 2021/07 */
                }

                .itemLogo {
                    &#logoLorfolio {
                        text-align: center; /*2021/07 */
                        /*width:34%;*/
                        /*padding: 0 0 0 8%;*/
                    }
                }
                #facilite {
                    text-align: center; /*2021/07 */
                    width:90%;
                    padding: 0 5%; /*2021/07 */
                    /*width:auto;
                    padding: 0; 2021/07 */
                    font-size:3.4vw ;
                    line-height: 1em;
                    vertical-align: top;

                    .retourLigne {
                        /*
                            display: block !important;
                            position: relative;
                            padding: 0;
                            margin: 0;
                            width: 0;
                            height: 0;
                        */
                        }

                    @media screen and (min-width: 800px) {
                        font-size:1.8em ;
                    }
                }

                .nombril {
                    width: 65%;
                    min-height: unset !important;
                    top: 50px;
                }

                @media screen and (max-width: 860px) {
                    min-height: 360px;
                    .nombril {
                        width: 55%;
                        top: 50px;
                        min-width: 380px;
                        }
                }

            }


        }

    &.addeoLargeLow {
        #header {
            #headerLogosPro {
                .nombril {
                    width: 50%;
                    top: 40px;
                }
            }
            .markOrientest  {
                #headerLogosPro {
                    min-height: 400px;
                    .nombril {
                        width: 40%;
                        top: 15px;
                    }
                }
            }
        }
    }
}
    #app {
        &.addeoSmall {
            &.pro {
                #header {
                    .gauche {
                        background: no-repeat left bottom transparent url("~@/assets/img/pro/header/perso-gaucheS.png");
                        width: 100%;
                        display:block;
                    }
                    .droite {
                        display:none;
                    }
                }
            }
        }
    }

    .addeoSmall {

        .markOrientest {
        }
        #headerLogosPro {
            text-align: center;
            min-height: 400px;
            background: no-repeat center bottom transparent url("~@/assets/img/pro/header/evolution-emploi-small.png");
            background-size:contain;

            @media screen and (max-width: 442px) {
            background: no-repeat center bottom transparent url("~@/assets/img/pro/header/evolution-emploi-xsmall.png");
                background-size:contain;
            }
            @media screen and (max-width: 380px) {
                background: no-repeat center bottom transparent url("~@/assets/img/pro/header/evolution-emploi-xxsmall.png");
                background-size:contain;
                }

            .itemLogo {
                text-align: center;

                &#logoLorfolio {
                    width:90%;
                    margin:0 auto;
                    padding: 0;

                    a {
                        width:100%;
                        max-width: 400px;
                        display:inline-block;
                        text-align: center;
                    }
                }
            }
                #facilite {
                    text-align: center;
                    width:90%;
                    margin:0.4em 5% 0;
                    padding: 0;
                    font-size: 2em;
                    line-height: 1.1em;
                    text-align: center;

                    @media screen and (max-width: 500px) {
                        font-size: 6vw;
                    }

                    span {
                        width:100%;
                        max-width: 400px;
                        display:inline-block;
                        text-align: left;
                    }
                }

            .nombril {
                display:none;
                /*
                width: 80%;
                top:65%;
                transform: translateY(-50%) translateX(-50%);
                min-height: unset !important;
                min-width: 320px;
                max-width: 100%;

                @media screen and (max-width: 360px) {
                    top:60%;
                }
                */
            }
        }
    }


    #headerLogosPro {
        width:96%;
        max-width: 1200px;
        min-height: 600px;
        height:auto;
        /*background-color: transparent;*/
        text-align: left;
        padding:2em 2% 0;
        margin:0 auto;

        .dessous {
            z-index:1;
        }
        .dessus {
            z-index:2;
            position:relative;
        }

        .itemLogo {
            margin:0 auto;
            display:inline-block;
            vertical-align: middle;
            text-align: left;
        }
        #logoLorfolio {
            width:70%;
            a {
                max-width:412px;
                display:inline-block;
                width: 100%;
            }
        }
        #facilite {
            width:100%;
            max-width:900px;
            text-align: left;
            text-transform: uppercase;
            font-size:2.2vw ;
            line-height: 1.1em;
            padding:0 3% 0 0;
            margin:0;
            color:#4B3C8F;
            font-weight: normal;

            span.retourLigne {
                /*
                display: inline !important;
                position: relative;
                padding: 0;
                */
                }


            @media screen and (min-width: 1200px) {
                font-size:1.7em ;
            }

        }
        #logoGE {
            margin: 0 0 0 auto;
            width:30%;
            max-width:162px;
            text-align: right;
        }

        .nombril {
            position: absolute;
            top:0;
            left:50%;
            transform: translateX(-50%);
            max-height: 100vh;
            min-height: 560px !important;
        }
    }
    .markOrientest  {
        #headerLogosPro {
            min-height: 560px;
            .nombril {
                top:-20px;
                }
        }
    }

#app.pro {
    #motsCroises {
        display:none;
    }


    #header.markOrientest {
        .headerOrientest {
            .bas {
                background: no-repeat center bottom transparent url("~@/assets/img/oe/pro/decoupageHaut.png");
                position: absolute;
                height: 100%;
                width: 100%;
                bottom:0;
                left:0;
            }
        }
    }

    &.addeoSmall {
        #header.markOrientest {

            .gauche {
                display:none;
            }
            .droite {
            }
            .headerOrientest {
                max-height: 280px;
                min-height: 280px;
                height: 280px;
                border-top:4px solid #35AC98;
                position: relative;

                #logoLorfolio.itemLogo {
                    z-index: 1;
                    text-align: center;
                    max-width: 262px;
                    width:90%;
                    margin:0 auto;
                    padding: 1.2em 0 0;

                    a {
                        width:100%;
                        max-width: 400px;
                        display:inline-block;
                        text-align: center;
                    }

                    #facilite {
                        text-transform: uppercase;
                        color:#4B3C8F;
                        font-weight: normal;
                        vertical-align: top;
                        text-align: center;
                        width:100%;
                        margin:0.2em 0 0;
                        padding: 0;
                        /*
                        font-size: 1.2em;
                        */
                        font-size: 0.88em;
                        line-height: 1.1em;
                        text-align: center;


                        span {
                            width:100%;
                            max-width: 400px;
                            display:inline-block;
                            text-align: left;
                        }
                    }
                }
                .illustration {
                    /*background: no-repeat left bottom transparent url("~@/assets/img/oe/pro/header/s-320.png");*/
                    background: no-repeat left bottom transparent url("/img/oe/pro/cache/s-320.png");
                    position: absolute;
                    height: 100%;
                    width: 95%;
                    z-index:0;
                    bottom:45px;
                    left:5%;
                }

            }



            @media screen and (max-width: 540px) {
                .droite {
                    display:none;
                }
            }
            @media screen and (min-width: 541px) {
                .headerOrientest {
                    #logoLorfolio.itemLogo {
                        position: absolute;
                        height: auto;
                        width: 45%;
                        left:0;
                        top:0;
                        padding:0;
                        margin:80px 0 0 1%;
                        text-align: center;
                        a, #facilite {
                            width:97%;
                            max-width: 223px;
                        }
                        #facilite {
                            margin:0.2em auto 0;
                            /*
                            font-size: 1.0em;
                            */
                            font-size: 0.75em;
                        }
                    }
                    .illustration {
                        display:none;
                    }
                }
                .droite {
                    display:block;
                    /*background: no-repeat center bottom transparent url("~@/assets/img/oe/pro/header/s-640.png");*/
                    background: no-repeat center bottom transparent url("/img/oe/pro/cache/s-640.png");
                    width:65%;
                    background-size:contain;
                    bottom: 45px;
                    right:auto;
                    left:45%;
                }
            }


        }
    }
    &.addeoMedium {
        #header.markOrientest {

            .gauche {
                display:none;
            }
            .droite {
                display:block;
                /*background: no-repeat center bottom transparent url("~@/assets/img/oe/pro/header/m-641.png");*/
                background: no-repeat center bottom transparent url("/img/oe/pro/cache/m-641.png");
                width:55%;
                background-size:contain;
                bottom: 45px;
                right:auto;
                left:45%;
            }
            .headerOrientest {
                max-height: 220px;
                min-height: 220px;
                height: 220px;
                /*border-top:4px solid #35AC98;*/
                position: relative;

                #logoLorfolio.itemLogo {
                    position: absolute;
                    z-index: 1;
                    height: auto;
                    width: 45%;
                    left:0;
                    top:0;
                    padding:0;
                    margin:60px 0 0 1%;
                    text-align: center;
                    a, #facilite {
                        width:97%;
                        max-width: 240px;
                        display:inline-block;
                        text-align: center;
                    }
                    #facilite {
                        margin:0.4em auto 0;
                        /*
                        font-size: 1.1em;
                        */
                        font-size: 0.8em;

                        text-transform: uppercase;
                        color:#4B3C8F;
                        font-weight: normal;
                        vertical-align: top;
                        text-align: center;
                        width:100%;
                        padding: 0;
                        line-height: 1.1em;
                        text-align: center;


                        span {
                            width:100%;
                            max-width: 400px;
                            display:inline-block;
                            text-align: left;
                        }
                    }
                }
                .illustration {
                    display:none;
                }

            }


            @media screen and (max-width: 840px) {

            }
            @media screen and (min-width: 721px) {
                .headerOrientest {
                    #logoLorfolio.itemLogo {
                        a, #facilite {
                            max-width: 290px;
                        }
                        #facilite {
                            /*
                            font-size: 1.3em;
                            */
                            font-size: 0.98em;
                        }
                    }
                }

            }
            @media screen and (min-width: 801px) {
                .droite {
                    /*background: no-repeat left bottom transparent url("~@/assets/img/oe/pro/header/m-991.png");*/
                    background: no-repeat left bottom transparent url("/img/oe/pro/cache/m-991.png");
                    bottom:34px;
                }
                .headerOrientest {
                    #logoLorfolio.itemLogo {
                        a, #facilite {
                            width:97%;
                            max-width: 300px;
                        }
                        #facilite {
                            /*
                            font-size: 1.36em;
                            */
                            font-size: 1.0em;
                        }
                    }
                }

            }


        }
    }
    &.addeoLarge {
        #header.markOrientest {

            #motsCroises {
                max-width: 440px;
                position:absolute;
                display:block;
                /*background: no-repeat center center transparent url("~@/assets/img/oe/pro/header/l-mots-croises.png");*/
                background: no-repeat center center transparent url("/img/oe/pro/cache/l-mots-croises.png");
                width:30%;
                background-size:cover;
                bottom: 25px;
                right:16%;
                left:auto;
                height: 100%;
            }
            .gauche {
                display:block;
                /*background: no-repeat center bottom transparent url("~@/assets/img/oe/pro/header/l-persos-gauche.png");*/
                background: no-repeat center bottom transparent url("/img/oe/pro/cache/l-persos-gauche.png");
                width:26%;
                background-size:contain;
                bottom: 45px;
                right:36%;
                left:auto;
            }
            .droite {
                display:block;
                /*background: no-repeat right bottom transparent url("~@/assets/img/oe/pro/header/l-persos-droite.png");*/
                background: no-repeat right bottom transparent url("/img/oe/pro/cache/l-persos-droite.png");
                width:24%;
                background-size:contain;
                bottom: 30px;
                right:0;
                left:auto;
            }
            .headerOrientest {
                max-height: 320px;
                min-height: 320px;
                height: 320px;
                /*border-top:4px solid #35AC98;*/
                position: relative;

                #logoLorfolio.itemLogo {
                    position: absolute;
                    z-index: 1;
                    height: auto;
                    width: 40%;
                    left:0;
                    top:0;
                    padding:0;
                    margin:100px 0 0;
                    text-align: center;
                    a, #facilite {
                        width:97%;
                        max-width: 300px;
                        display:inline-block;
                        text-align: center;
                    }
                    #facilite {
                        margin:0.4em auto 0;
                        /*
                        font-size: 1.36em;
                        */
                        font-size: 1.0em;
                        display: block;

                        text-transform: uppercase;
                        color:#4B3C8F;
                        font-weight: normal;
                        vertical-align: top;
                        text-align: center;
                        width:100%;
                        padding: 0;
                        line-height: 1.1em;
                        text-align: center;


                        span {
                            width:100%;
                            max-width: 400px;
                            display:inline-block;
                            text-align: left;
                        }
                    }
                }
                .illustration {
                    display:none;
                }

            }



            @media screen and (min-width: 1201px) {
                #motsCroises {
                    right:16%;
                }
                .headerOrientest {
                    #logoLorfolio.itemLogo {
                        a, #facilite {
                            max-width: 350px;
                        }
                        #facilite {
                            /*
                            font-size: 1.6em;
                            */
                            font-size: 1.18em;
                        }
                    }
                }
            }
            @media screen and (min-width: 1301px) {
                #motsCroises {
                    right:15%;
                }
            }

            @media screen and (min-width: 1601px) {
                .gauche {
                    background-position:right bottom;
                    bottom: 10px;
                }
                .droite {
                    bottom: 20px;
                }
                .headerOrientest {
                    #logoLorfolio.itemLogo {
                        a, #facilite {
                            /*
                            max-width: 385px;
                            */
                            max-width: 440px;
                            text-align:left;
                        }
                        #facilite {
                            font-size: 1.48em;
                            span {
                                margin-left:0;
                            }
                        }
                    }
                }

            }


        }
    }
}


</style>
