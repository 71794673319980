<template>
  <div id="bandeauComm">
      <div id="gaucheBandeauComm">
      </div>
      <div class="annonceBandeauComm">
          <div v-if="!paramPRO" id="annonceBandeauComm" >
          </div>
          <div v-if="paramPRO" id="annonceProBandeauComm" >
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'Inscription',
    props: {
    },
    data() {
        return {
            hovered:false,
            tmp:true,
        }
    },
    computed: {
        paramPRO() {
            return(this.$router.options.paramPRO);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    #app.bandeauComm {


        &:not(.addeoSmall)  {
            #bandeauComm {
                border-bottom: 8px solid #FFD430;
                box-shadow: 0px 1px 20px #806464;
            }
        }

        #bandeauComm {
            background: no-repeat center top transparent url("~@/assets/img/comm/backgroundL.svg");
            background-size: cover;
            z-index:1;
            div {
                background-size: auto;
            }
            #gaucheBandeauComm {
                position:absolute;
                width:35%;
                left:0;
                top:0;
            }
            .annonceBandeauComm {
                position:absolute;
                width:70%;
                right:0%;
                top:0;
                text-align: center;
                div {
                    width:100%;
                }
            }
        }

        &.pro {
            #bandeauComm {
                border-bottom-color: #35AC98;
            }
        }

        &.addeoLarge, &.addeoLargeLow {
            #bandeauComm {
                min-height:164px;
                div {
                    min-height:164px;
                }
                #gaucheBandeauComm {
                    width:50%;
                    margin-top:7px;
/*                    background: no-repeat center center transparent url("~@/assets/img/comm/gaucheL.svg");*/
                    background: no-repeat 10% 50% transparent url("~@/assets/img/comm/gaucheL.svg");
                    max-height:150px;
                    min-height:150px;
                }

                .annonceBandeauComm {
                    /*width: 70%;*/
                    right:unset;
                    left:396px;
                    width:60%;
                    max-width:1524px;
                    min-width:594px;
                    div {
                right:unset;
                        /*
                        left:600px;
                        right:5%;
                        margin:0 auto;
                        width: 75%;
                        */
                        left:18%;
                        width: 82%;
                        max-width: 800px;
                        margin:0;

                        @media screen and (max-width: 1640px) {
                            left:15%;
                            width: 85%;
                        }

                        @media screen and (max-width: 1400px) {
                            left:10%;
                            width: 90%;
                        }

                        @media screen and (max-width: 1250px) {
                            left:5%;
                            width: 85%;
                        }
                    }
                }
                #annonceBandeauComm {
                    background: no-repeat left center transparent url("~@/assets/img/comm/annonceL.svg");
                }
                #annonceProBandeauComm {
                    background: no-repeat left center transparent url("~@/assets/img/comm/annonceProL.svg");
                }
            }
        }
        &.addeoMedium:not(.addeoLargeLow) {
            #bandeauComm {
                background: no-repeat center top transparent url("~@/assets/img/comm/backgroundM.svg");
                background-size: cover;
                min-height:220px;
                div {
                    min-height:220px;
                }
                #gaucheBandeauComm {
                    background: no-repeat center center transparent url("~@/assets/img/comm/gaucheM.svg");
                    width:50%;
                    margin-top:20px;
                    max-height:180px;
                    min-height:180px;
                }
                .annonceBandeauComm {
                    width:50%;
                    div {
                        right:5%;
                        width: 80%;
                        max-width: 300px;
                        margin:0 auto;
                        }
                }
                #annonceBandeauComm {
                    background: no-repeat center center transparent url("~@/assets/img/comm/annonceM.svg");
                }
                #annonceProBandeauComm {
                    background: no-repeat center center transparent url("~@/assets/img/comm/annonceProM.svg");
                }
            }
        }
        &.addeoSmall  {
            #bandeauComm {
                background: no-repeat center top transparent url("~@/assets/img/comm/backgroundS.svg");
                background-size: cover;
                min-height:178px;
                div {
                    min-height:178px;
                }
                #gaucheBandeauComm {
                    background: no-repeat center top transparent url("~@/assets/img/comm/gaucheS.svg");
                    width:100%;
                    min-height: 80px;
                    max-height: 80px;
                    height: 80px;
                    top:5px;
                }
                .annonceBandeauComm {
                    width:100%;
                    div {
                        width: 90%;
                        max-width: 300px;
                        margin:95px auto 14px;
                        min-height:70px;
                        }
                }
                #annonceBandeauComm {
                    background: no-repeat center bottom transparent url("~@/assets/img/comm/annonceS.svg");
                }
                #annonceProBandeauComm {
                    background: no-repeat center bottom transparent url("~@/assets/img/comm/annonceProS.svg");
                }
            }
        }
    }
</style>
