<template>
  <div id="footerSocial" class="itemFooter">
    <ul class="social">
<!--
        <li><a href="https://www.facebook.com/orientationgrandest " target="_blank" rel="noopener" class="facebook" title="Facebook Orient'Est"> <span class="fab fa-facebook" aria-hidden="true"></span> </a></li>
        <li><a href="https://twitter.com/orient_form_GE" target="_blank" rel="noopener" class="twitter" title="Twitter Grand Est"> <span class="fab fa-twitter" aria-hidden="true"></span> </a></li>
-->
        <li><a href="https://bit.ly/YoutubeOrientation" target="_blank" rel="noopener" class="youtube" title="Chaîne Youtube Orient'Est"> <span class="fab fa-youtube" aria-hidden="true"></span> </a></li>
        <li v-if="!$parent.$parent.$parent.sitePRO"><a href="https://fr.linkedin.com/company/orientation-formation-grand-est?trk=public_post_feed-actor-name" target="_blank" rel="noopener" class="linkedin" title="Linkedin Grand Est"> <span class="fab fa-linkedin" aria-hidden="true"></span> </a></li>
        <li v-if="$parent.$parent.$parent.sitePRO"><a href="https://www.linkedin.com/groups/8972559" target="_blank" rel="noopener" class="linkedin" title="Linkedin Grand Est"> <span class="fab fa-linkedin" aria-hidden="true"></span> </a></li>
        <li><a href="https://www.instagram.com/odmgrandest/" target="_blank" rel="noopener" class="instagram" title="Instagram olympiades des metiers Grand Est"> <span class="fab fa-instagram" aria-hidden="true"></span> </a></li>
    </ul>



  </div>
</template>

<script>
export default {
    name: 'FooterSocial',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .addeoSmall, .addeoMedium:not(.addeoLargeLow) {
        #footer {
            #containerFooter {
                #footerSocial {
                    display: block;
                    float:none;
                    /*margin:4em auto 3em;*/
                    margin:1em auto 2em;
                    padding:0;
                }
            }
        }
    }

#footer {
    #containerFooter {
        text-align: center;
        .itemFooter {
            display: block;
            width: 90%;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
        div#footerSocial {
            float:none;
            &.itemFooter {
                background-color: transparent;
                vertical-align: top;

                ul.social {
                    padding:0;
                    margin:0;

                    li {
                        display:inline-block;
                        margin : 0 0;
                        a {
                            span {
                                &.fa-youtube {
                                    margin-right: 6px;
                                }
                                font-size:1.7em;
                                min-width:1.4em;
                                min-height:1.4em;
                                vertical-align: middle;


                                    color:#006eab;
                                    background-color: transparent;
                                    border:none;
                                    font-size: 30px;
                                /*
                                    background-color: #006EA9;
                                    color: white;
                                    border: 0 solid #006EA9;
                                    border-width: 0.1em 0.2em;
                                    border-radius: 0.9em;
                                    font-size: 1.3em;
                                */

                                    margin-left:4px;
                                    margin-right:2px;

                                    &.fa-linkedin {
                                        color: #006EA9;
                                    }
                                /*
                                    &.fa-facebook {
                                    font-size: 1.1em;
                                    border-radius: 1em;
                                    border-width: 0.05em 0.15em;
                                    }
                                */
        /*
                                &.fa-twitter {
                                    background-color: #006EA9;
                                    color: white;
                                    border-left: 1px solid #006EA9;
                                    border-right: 1px solid #006EA9;
                                    font-size: 1em;
                                    border-radius: 2px;
                                    margin-left:4px;
                                    margin-right:2px;
                                }

                                &.fa-instagram {
                                }
        */

                                &:before {
                                    line-height: 1.6em;
                                    vertical-align: middle;
                                }

                            }

                        }
                    }
                }
            }
        }
    }
}
</style>
