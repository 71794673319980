<template>
    <div class="public">

        <span v-if="!$parent.addeoSmall"    @mouseover="hover = true" @mouseleave="hover = false">
            <img  class="fond survol derriere" src="../../../assets/img/public/rondexter.svg" alt="" />
            <img v-if="hover" class="illustration" :src="getMevUrl" :alt="data.itemsTxt[item]" />
            <img v-if="!hover" class="illustration" :src="getImgUrl" :alt="data.itemsTxt[item]" />
            <img class="ombre" :src="getOmbreUrl" :alt="data.itemsTxt[item]" />
            <span class="legende" v-if="!data.items[item]">
                <span v-html="data.itemsTxt[item]"></span>
            </span>
            <img  class="fond" src="../../../assets/img/public/rondinter.svg" alt="" />
            <img  class="fond survol" src="../../../assets/img/public/rondintermauve.svg" alt="" />
            <img  class="dessus" src="../../../assets/img/public/rondouvre.svg" alt="" />
        </span>

        <span v-if="$parent.addeoSmall" class="containerSituation">
            <div class="situation" @click.stop="afficher=!afficher" v-bind:class="{affiche : afficher}">
                <span v-html="data.itemsTxtS[item]"></span>
                <span class="fA"><i class="fa fa-angle-right"></i></span>
            </div>


<transition name="slide">
            <div class="transition" v-if="afficher">
                <span class="bulles gauche" v-if="nbBulles" >
                    <img  class="" src="../../../assets/img/public/bulles/small.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][0]"></span>
                </span>
                <span class="bulles droite"  v-if="nbBulles>1">
                    <img  class="" src="../../../assets/img/public/bulles/small.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][1]"></span>
                </span>
                <span class="bulles gauche" v-if="nbBulles>2" >
                    <img  class="" src="../../../assets/img/public/bulles/small.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][2]"></span>
                </span>
                <span class="bulles droite"  v-if="nbBulles>3">
                    <img  class="" src="../../../assets/img/public/bulles/small.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][3]"></span>
                </span>
                <span class="bulles gauche" v-if="nbBulles>4" >
                    <img  class="" src="../../../assets/img/public/bulles/small.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][4]"></span>
                </span>
                <span class="bulles droite"  v-if="nbBulles>5">
                    <img  class="" src="../../../assets/img/public/bulles/small.svg" alt="" />
                    <span class="txt" v-html="data.itemsCont[item][5]"></span>
                </span>
            </div>
</transition>

        </span>

    </div>
</template>

<script>
export default {
    name: 'Public',
        props: {
            data: {
                type: Object
            },
            item: {
                type: String
            }
        },
    data() {
        return {
            hover:false,
            afficher:false,
            tmp:true
        }
    },
    computed: {
        nbBulles() {
            /*
            if (!this.ready) {
                return(0);
            }
            */
            return(this.data.itemsCont[this.item].length)
        },
        getImgUrl() {
            var image = "/img/publics/"+this.data.images[this.item]+".svg";
            return  image
        },
        getOmbreUrl() {
            var image = "/img/publics/ombre-"+this.data.images[this.item]+".png";

            return  image
        },
        getMevUrl() {

            var image = "/img/publics/mev-"+this.data.images[this.item]+".svg";
            return  image
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .addeoSmall {
        #publics {
            padding: 0;
            margin-top: 2em;

            #items {

                .item {
                    display:block;
                    width: 100%;
                    max-width: 100%;
                    text-align: left;
                    color:white;
                    padding:0;
                    cursor:auto;

                    .public {
                        margin: 0;

                        .containerSituation {
                            position: relative;
                            display: block;
                            background-color: #EDF2F5;
                            margin: 0 0 1px 0;

                            span {
                                position: relative;
                            }

                            .situation {
                                padding: 1em 2em 1em 1em;
                                background-color: #2F6B75;
                                margin: 0;
                                vertical-align: middle;
                                font-size: 1.2em;
                                line-height: 1.6em;
                                cursor:pointer;

                                @media screen and (max-width: 400px) {
                                    font-size:1.1em ;
                                    padding: 1em 0.8em;
                                }

                                &.affiche {
                                    margin:0;
                                }

                                .fA {
                                    float:right;
                                    font-size:1.6em;
                                }
                            }
                            .bulles {
                                display: block;
                                text-align: center;
                                margin: 0 0 -1.4em 0;

                                &:last-of-type {
                                    margin:0;
                                }


                                img {
                                    width: 85%;
                                    max-width: 480px;
                                    margin:0 auto;
                                    transform: scaleY(0.7) ;
                                }

                                &.gauche {
                                    img {
                                        transform: scaleX(-1) scaleY(0.7) ;
                                    }
                                    .txt {
                                        padding:0 15% 0 25%;
                                        width: 60%;
                                    }
                                }
                                &.droite {

                                    img {
                                    }
                                    .txt {
                                        padding:0 24% 0 16%;
                                        width: 60%;
                                    }
                                }

                                .txt {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    z-index: 13;
                                    color: #2c3e50;
                                    transform: translateX(-50%) translateY(-50%);
                                    line-height: 1em;
                                    max-width: 400px;
                                    display: inline-block;
                                    height: auto;
                                    text-align: left;
                                    font-size:3.4vw ;
                                    line-height: 1.2em;
                                }
                            }
                        }
                    }
                }
            }

            .transition {
                display:block;
            }
.slide-enter-active, .slide-leave-active {
    transition: all .8s ease;
    max-height:700px;
}
.slide-enter, .slide-leave-to {
  max-height: 0;
}

        }
    }

</style>
