/* eslint-disable no-console */

import { register } from 'register-service-worker'

/* @ngInject */


function invokeServiceWorkerUpdateFlow(registration) {
        if (registration.waiting) {
            // let waiting Service Worker know it should became active
            registration.waiting.postMessage('SKIP_WAITING')
        }
    /*
    // TODO implement your own UI notification element
    notification.show("New version of the app is available. Refresh now?");
    notification.addEventListener('click', () => {
        if (registration.waiting) {
            // let waiting Service Worker know it should became active
            registration.waiting.postMessage('SKIP_WAITING')
        }
    })
    */
}



console.log('registerServiceWorker')
/**/
if (0 || (process.env.NODE_ENV === 'production')) {

    const registredEvent = new Event('swRegistered');
    //const updatedEvent = new Event('swUpdated');
    const errorEvent = new Event('swError');

//const sw = `${process.env.BASE_URL}service-worker-addeo.js`; // si gestion manuelle
const sw = `${process.env.BASE_URL}service-worker.js`;
const laVersion = process.env.VUE_APP_VERSION;

console.log('registerServiceWorker production : ', sw);

    // wait for the page to load
window.addEventListener('load', async () => {

if ('serviceWorker' in navigator) {
    console.log('Service worker in navigator');

  // Register a service worker hosted at the root of the
  // site using the default scope.
  navigator.serviceWorker.laVersion = laVersion;

  register(sw, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      document.dispatchEvent(registredEvent); // on transmet l'enregistrement à app.vue
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
        //const updatefoundEvent = new CustomEvent('swUpdated', { detail: registration })
      //document.dispatchEvent(updatefoundEvent); // on transmet l'enregistrement à app.vue
      console.log('New content is downloading.')
    },
    updated (registration) {
        const updatedEvent = new CustomEvent('swUpdated', { detail: registration })
      document.dispatchEvent(updatedEvent); // on transmet l'enregistrement à app.vue
      console.log('New content is available : please refresh.') // proposer : window.location.reload(true)
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      document.dispatchEvent(errorEvent); // on transmet l'erreur à app.vue
      console.error('Error during service worker registration:', error)
    }
  })
/*
  navigator.serviceWorker.register(sw).then(
    (registration) => {
        registration.laVersion = laVersion;
        console.log('Service worker registration succeeded : ', registration);

        registration.addEventListener('updatefound', () => {

            // A wild service worker has appeared in reg.installing!
            const newWorker = registration.installing;

            console.log('Service worker : updatefound !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -> ', newWorker);

            //newWorker.state;
            // "installing" - the install event has fired, but not yet complete
            // "installed"  - install complete
            // "activating" - the activate event has fired, but not yet complete
            // "activated"  - fully active
            // "redundant"  - discarded. Either failed install, or it's been
            //                replaced by a newer version
            const updatedEvent = new CustomEvent('swUpdated', { detail: registration })
            document.dispatchEvent(updatedEvent); // on transmet l'enregistrement à app.vue
      });


      //registration.update();
    })
    .catch((error) => {
      console.log('Service worker registration error:', error)
    });
*/
} else {
    console.log('Service workers are not supported')
    }
}) //wait load
/*
  register(sw, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      console.log(sw)
    },
    registered () {
      document.dispatchEvent(registredEvent); // on transmet l'enregistrement à app.vue
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
        const updatedEvent = new CustomEvent('swUpdated', { detail: registration })
      document.dispatchEvent(updatedEvent); // on transmet l'enregistrement à app.vue
      console.log('New content is available; please refresh.') // proposer : window.location.reload(true)
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      document.dispatchEvent(errorEvent); // on transmet l'erreur à app.vue
      console.error('Error during service worker registration:', error)
    }
  })
*/
}
/**/





/*
// eslint-disable no-console

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
*/
