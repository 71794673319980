<template>
  <div class="home" v-bind:class="{markOrientest : markOrientest}">
    <bloc-connexion v-if="!addeoSmall" :header="true" />
    <bloc-bandeau v-if="paramBandeauComm" />
    <bloc-header/>
    <bloc-usages/>
    <bloc-reseau/>
    <bloc-avantages/>
    <bloc-inscription/>
    <bloc-carousel/>
    <bloc-footer/>
  </div>
</template>


<script>
import Connexion from '@/components/Connexion.vue'
import BandeauComm from '@/components/BandeauComm.vue'
import Header from '@/components/Header.vue'
import Usages from '@/components/Usages.vue'
import Reseau from '@/components/Reseau.vue'
import Avantages from '@/components/Avantages.vue'
import Inscription from '@/components/Inscription.vue'
import Carousel from '@/components/Carousel.vue'
import Footer from '@/components/Footer.vue'
    
export default {
  name: 'Portail-Lorfolio',
        data() {
            return {
                tmp:true,
                accueil:true
            }
        },
        components: {
            'bloc-connexion': Connexion,
            'bloc-bandeau': BandeauComm,
            'bloc-header': Header,
            'bloc-usages': Usages,
            'bloc-reseau': Reseau,
            'bloc-avantages': Avantages,
            'bloc-inscription': Inscription,
            'bloc-carousel': Carousel,
            'bloc-footer': Footer,
        },
        computed:{
                addeoSmall() {
                    return(this.$parent.addeoSmall);
                },
                addeoMedium() {
                    return(this.$parent.addeoMedium);
                },
                addeoLarge() {
                    return(this.$parent.addeoLarge);
                },
                paramBandeauComm() {
                    return(this.$router.options.paramBandeauComm);
                },
                markOrientest() {
                    return(this.$router.options.markOrientest);
                }
        }
}


</script>
