<template>
    <div id="avantage">

        <div class="item">
                <img class="illustration" :src="getImgUrl" :alt="data.itemsTitre[item]" />
                <span class="titre" >
                    <span v-html="data.itemsTitre[item]"></span>
                </span>

                <div class="contenu" >
                    <span v-html="data.itemsCont[item]"></span>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Avantage',
        props: {
            data: {
                type: Object
            },
            item: {
                type: String
            }
        },
    data() {
        return {
            tmp:true
        }
    },
    computed: {
        getImgUrl() {
            var image = "/"+this.data.images[this.item];
            //var toto = require(image);
            //return imageName ? toto : ''
            return  image
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #avantage {


    }
</style>
