<template>
    <div id="usages">
        <div class="containerAffichage">
            <h2 class="sousTitre" v-if="markOrientest"><img src="../assets/img/header/logoMonOrientest.svg" alt="Mon OrientEst" class="centrer marqueOrientest" /><span class="commeSousTitre">,</span> c'est quoi ?</h2>
            <h2 class="sousTitre" v-if="!markOrientest"><img src="../assets/img/header/logoLorfolio.svg" alt="Lorfolio" class="centrer" /><span class="commeSousTitre">,</span> c'est quoi ?</h2>


            <div id="items">

                <div class="item" id="passeport" @click.stop="reinit('passeport');">
                    <item-usage :data="lesDatas" item="passeport" :isOpen="items.passeport" />
                </div>
                <div class="item" id="portfolio" @click.stop="reinit('portfolio');">
                    <item-usage :data="lesDatas" item="portfolio" :isOpen="items.portfolio" />
                </div>
                <div class="item" id="pepiniere" @click.stop="reinit('pepiniere');">
                    <item-usage :data="lesDatas" item="pepiniere" :isOpen="items.pepiniere" />
                </div>
                <div class="item" id="fenetre" @click.stop="reinit('fenetre');">
                    <item-usage :data="lesDatas" item="fenetre" :isOpen="items.fenetre" />
                </div>
                <div class="item" id="ressources" @click.stop="reinit('ressources');">
                    <item-usage :data="lesDatas" item="ressources" :isOpen="items.ressources" />
                </div>
                <div class="item" id="coffre" @click.stop="reinit('coffre');">
                    <item-usage :data="lesDatas" item="coffre" :isOpen="items.coffre" />
                </div>
                <div class="item reseau" id="reseau" @click.stop="reinit('reseau');">
                    <item-usage :data="lesDatas" item="reseau" :isOpen="items.reseau" />
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Usage from '@/components/usage/Usage.vue'
export default {
    name: 'Usages',
    components: {
        'item-usage': Usage,
        },
    props: {
    },
    data() {
        return {
            images : {"passeport":"img/usages/imagePasseport.jpg", "portfolio":"img/usages/imagePortfolio.jpg", "pepiniere":"img/usages/imagePepiniere.jpg", "fenetre":"img/usages/imageFenetre.jpg", "ressources":"img/usages/imageRessources.jpg", "coffre":"img/usages/imageCoffre.jpg", "reseau":"img/usages/imageReseau.jpg"},
            imagesS : {"passeport":"img/usages/imagePasseport.jpg", "portfolio":"img/usages/imagePortfolio.jpg", "pepiniere":"img/usages/imagePepiniere.jpg", "fenetre":"img/usages/imageFenetre.jpg", "ressources":"img/usages/imageRessources.jpg", "coffre":"img/usages/imageCoffre.jpg", "reseau":"img/usages/imageReseauS.jpg"},
            items : {"passeport":false,
                "portfolio":false,
                "pepiniere":false,
                "fenetre":false,
                "ressources":false,
                "coffre":false,
                "reseau":false},
            itemsTxt : {"passeport":"Un passeport<span class='retourLigne'> </span>professionnel",
                "portfolio":"Un e-portfolio<span class='retourLigne'> </span>de compétences",
                "pepiniere":"Une pépinière de<span class='retourLigne'> </span>projets professionnels",
                "fenetre":"Une fenêtre<span class='retourLigne'> </span>sur l’emploi",
                "ressources":"Un espace ressources",
                "coffre":"Un coffre numérique",
                "reseau":"Un réseau professionnel à mes côtés"},
            itemsCont : {"passeport":"Renseigner mes expériences, diplômes et formations<span class='retourLigne'>&nbsp;</span>pour les mobiliser à tout moment",
                "portfolio":"Identifier et détailler<span class='retourLigne'>&nbsp;</span>ce dont je suis capable<span class='retourLigne'>&nbsp;</span>et y associer des justificatifs",
                "pepiniere":"Définir mes objectifs professionnels<span class='retourLigne'>&nbsp;</span>et un plan d’actions<span class='retourLigne'>&nbsp;</span>pour les atteindre",
                "fenetre":"Créer facilement<span class='retourLigne'>&nbsp;</span>un site web personnel<span class='retourLigne'>&nbsp;</span>et des CV sur mesure",
                "ressources":"Trouver des réponses<span class='retourLigne'>&nbsp;</span>à mes questions<span class='retourLigne'>&nbsp;</span>parmi une sélection<span class='retourLigne'>&nbsp;</span>de liens et documents utiles<span class='retourLigne'>&nbsp;</span>sur les métiers et les formations",
                "coffre":"Stocker et classer de façon sécurisée<span class='retourLigne'>&nbsp;</span>tous les formats de documents,<span class='retourLigne'>&nbsp;</span>disponibles partout<span class='retourLigne'>&nbsp;</span>et tout le temps",
                "reseau":"Bénéficier d'un accompagnement professionnel en Grand Est,<span class='retourLigne'>&nbsp;</span>sur site ou à distance, pour atteindre mes objectifs avec Lorfolio"},
            itemsContMarkOrientest : {"passeport":"Renseigner mes expériences, diplômes et formations<span class='retourLigne'>&nbsp;</span>pour les mobiliser à tout moment",
                "portfolio":"Identifier et détailler<span class='retourLigne'>&nbsp;</span>ce dont je suis capable<span class='retourLigne'>&nbsp;</span>et y associer des justificatifs",
                "pepiniere":"Définir mes objectifs professionnels<span class='retourLigne'>&nbsp;</span>et un plan d’actions<span class='retourLigne'>&nbsp;</span>pour les atteindre",
                "fenetre":"Créer facilement<span class='retourLigne'>&nbsp;</span>un site web personnel<span class='retourLigne'>&nbsp;</span>et des CV sur mesure",
                "ressources":"Trouver des réponses<span class='retourLigne'>&nbsp;</span>à mes questions<span class='retourLigne'>&nbsp;</span>parmi une sélection<span class='retourLigne'>&nbsp;</span>de liens et documents utiles<span class='retourLigne'>&nbsp;</span>sur les métiers et les formations",
                "coffre":"Stocker et classer de façon sécurisée<span class='retourLigne'>&nbsp;</span>tous les formats de documents,<span class='retourLigne'>&nbsp;</span>disponibles partout<span class='retourLigne'>&nbsp;</span>et tout le temps",
                "reseau":"Bénéficier d'un accompagnement professionnel en Grand Est,<span class='retourLigne'>&nbsp;</span>sur site ou à distance, pour atteindre mes objectifs avec Mon Orient'Est"},
            tmp:true
        }
    },

    computed: {
        lesDatas() {
            var datas = new Object();
            datas.images= this.images;
            datas.imagesS = this.imagesS;
            datas.items= this.items;
            datas.itemsTxt= this.itemsTxt;
            if (this.markOrientest) {
                datas.itemsCont= this.itemsContMarkOrientest;
            }
            else {
                datas.itemsCont= this.itemsCont;
            }
            return(datas);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    },
    methods: {
        reinit(item) {
            item !== "passeport" ? this.items["passeport"]=false : null
            item !== "portfolio" ? this.items["portfolio"]=false : null
            item !== "pepiniere" ? this.items["pepiniere"]=false : null
            item !== "fenetre" ? this.items["fenetre"]=false : null
            item !== "ressources" ? this.items["ressources"]=false : null
            item !== "coffre" ? this.items["coffre"]=false : null
            item !== "reseau" ? this.items["reseau"]=false : null
            this.items[item]=!this.items[item];
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">



    .addeoMedium {
        #usages {

            .item {
                max-width:310px;
                width:32%;

                .legende span,
                .contenu span {
                    font-size:0.7em;
                    font-size:1.8vw ;
                    @media screen and (min-width: 900px) {
                        font-size:1em ;
                    }
                }
                .contenu span {
                    line-height: 1.4;

                    span.retourLigne {
                        display: inline !important;
                        position: relative;
                        padding: 0;
                    }
                }

                &.reseau {
                    width:100%;
                    .legende span, .contenu span {
                        font-size:1em;
                    }
                }
            }
        }
    }

    .addeoLarge, .addeoSmall {
        #usages {
            .item {
                .contenu {
                    span {
                        line-height: 1.6;
                    }
                }
            }
        }
    }
    .addeoMedium, .addeoLarge {
        #usages {

            .item {

                &.reseau {
                    /*max-width:1200px;*/
                    max-width:1150px;

                    img {
                        max-width:100%;
                    }

                    .legende, .contenu {
                        top: 70%;
                        height: 25%;

                        span {
                            /*padding: 0 4em;*/
                            display: inline-block;
                        }
                    }
                    .legende.titre {
                        top:5%;
                        height:40%;
                        span {
                            /*
                            padding-top: 3em;
                            */
                        }
                    }
                    .contenu {

                        height:54%;
                        top:40%;

                        span {
                            /*
                            padding: 3em 4em 0;
                            */
                        }
                    }
                }
            }
        }
    }

    #usages {
        width:100%;
        height:auto;
        background-color: transparent;

        .item {
            display:inline-block;
            width:100%;
            max-width:386px;
            cursor:pointer;
            overflow: hidden;

            img {
                max-width:100%;
            }

            .fond {
                position:absolute;
                top:0;
                left:0;
            }
            .legende, .contenu {
                position:absolute;
                width:100%;
                top:70%;
                left:0;
                right:0;
                height:22%;
                background-color: #356974;
                color:white;
                vertical-align: middle;
                font-weight: 600;

                span {
                    padding: 0 4em;
                    /*
                    padding: 5% 15% 0;
                    display: inline-block;
                    */
                    display: inline-block;
                    position: absolute;
                    text-align: center;
                    left: 0px;
                    max-width: 100%;
                    width: 80%;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    /*padding: 0px;*/

                        span.retourLigne {
                            display: block !important;
                            position: relative;
                            padding: 0;
                            margin: 0;
                            width: 0;
                            height: 0;
                        }
                    }

                    i {
                        margin-left:0.2em;
                        margin-top: -0.21em;
                        font-size:140%;
                        vertical-align: middle;
                    }

            }
            .legende.titre {

                top:5%;
                height:40%;

                background-color: #F5CA01 !important;
                span {
                    /*
                    padding-top: 15%;
                    */
                    color: #356974;

                }
            }
            .contenu {
                font-weight: normal;
                height:52%;
                top:40%;
                color:#356974;
                background-color: #EDF2F5;
                span {
                    /*line-height: 1.4;*/
                    line-height: 1.2;
                    /*
                    padding-top: 15%;
                    */
                }
            }

            .survol {
                display:none;
            }
            &:hover, &:focus {
                .fond {
                    display:none;
                }
                .survol {
                    display:block;
                }
                .legende {
                    background-color: #4B3C8F;
                }
            }

        }








        /* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(30%);
  opacity: 0;
}

    }
</style>
