<template>
    <div id="headerLogos">
        <div id="logoLorfolio" class="itemLogo">
            <a href="/" class="" title="Mon OrientEst" v-if="markOrientest">
                <img src="../../assets/img/header/logoMonOrientest.svg" alt="Mon OrientEst" class="centrer" />
            </a>
            <a href="/" class="" title="Lorfolio" v-if="!markOrientest">
                <img src="../../assets/img/header/logoLorfolio.svg" alt="Lorfolio" class="centrer" />
            </a>
            <h1 id="prepare" class="itemLogo">
                <span>
                    Toujours préparé<br/>pour mes choix professionnels
                </span>
            </h1>
        </div>
        <div v-if="!markOrientest && (addeoLarge || addeoLargeLow)" id="logoGE" class="itemLogo">
            <a href="https://www.grandest.fr" target="_blank" rel="noopener" class="" title="Région Grand Est">
                <img src="../../assets/img/header/logoGrandE.svg" alt="Région Grand Est" class="centrer" />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderLogos',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed:{
        addeoSmall() {
            return(this.$parent.$parent.$parent.addeoSmall);
        },
        addeoMedium() {
            return(this.$parent.$parent.$parent.addeoMedium);
        },
        addeoLarge() {
            return(this.$parent.$parent.$parent.addeoLarge);
        },
        addeoLargeLow() {
            return(this.$parent.$parent.$parent.addeoLargeLow);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .addeoMedium {
        #headerLogos {

            .itemLogo {
                &#logoLorfolio {
                    width:34%;
                    padding: 0 0 0 8%;
                }
                &#prepare {
                    width:54%;
                    padding: 0 1% 0 3%;
                    /*font-size: 1.1em;*/
                    font-size:2.5vw ;
                    line-height: 1em;
                    vertical-align: top;
                    @media screen and (min-width: 1200px) {
                        font-size: 1.7em;
                    }
                }
            }
        }
    }
    .addeoSmall {
        #headerLogos {

            .itemLogo {
                &#logoLorfolio {
                    max-width:410px;
                    width:90%;
                    margin:0 auto;
                    padding: 0;
                    text-align: left;

                    a {
                        width:100%;
                        max-width: 400px;
                        display:inline-block;
                        text-align: left;
                    }
                    #prepare {
                        width:100%;
                        margin:0.4em auto 0;
                        padding: 0;
                        font-size: 1.3em;
                        line-height: 1.1em;
                        text-align: left;

                        @media screen and (max-width: 460px) {
                            font-size:4.4vw;
                        }

                        span {
                            width:100%;
                            max-width: 400px;
                            display:inline-block;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }


    #headerLogos {
        width:96%;
        height:auto;
        background-color: transparent;
        text-align: center;
        padding:2em 2% 0;

        .itemLogo {
            margin:0 auto;
            display:inline-block;
            vertical-align: middle;
        }
        #logoLorfolio {
            max-width:412px;
            width:30%;
        }
        #prepare {
            width:50%;
            max-width:516px;
            text-align: left;
            text-transform: uppercase;
            /*font-size:2.2vw ;*/
            font-size:2.1vw ;
            line-height: 1.1em;
            padding:0 3% 0 2%;
            margin:0;
            color:#4B3C8F;
            font-weight: normal;

            @media screen and (min-width: 1200px) {
                font-size:1.7em ;
            }

        }
        #logoGE {
            width:15%;
            max-width:162px;
            text-align: left;
        }
    }


    #app:not(.addeoSmall) {
        .markOrientest {
            #headerLogos {
                .itemLogo {
                    vertical-align: baseline;
                }

                  min-height: 120px;

                  #logoGE {
                      /*
                      position: absolute;
                      left:68%;
                      top:2em;
                      */
                  }
                #logoLorfolio.itemLogo {
                    position: absolute;
                    height: auto;
                    max-width: 506px;
                    width: 80%;
                    left:50%;
                    transform: translate(-50%, 0); /* décalage de 50% de sa propre taille */
                    top:2em;
                    padding:0;
                    margin:0;
                    text-align: center;
                    #prepare {
                        margin-top:0.2em;
                    }
                    a, #prepare {
                        padding:0;
                        width:100%;
                        max-width: 506px;
                        display:inline-block;
                        text-align: left;
                        font-size: 1.6em;
                        @media screen and (max-width: 1360px) {
                            /*font-size:2vw ;*/
                        }
                    }
                }
            }
        }
    }

    #app.addeoMedium:not(.addeoLargeLow) {
        .markOrientest {
            #headerLogos {
                padding-top:1em;
                #logoLorfolio.itemLogo {
                    /*
                    position: relative;
                    right: unset;
                    */
                    top:1.4em;
                    width: 80%;
                    max-width: 506px;
                    a, #prepare {
                        width:100%;
                        max-width: 100%;
                        padding:0;
                        /*
                        font-size:3.9vw ;
                        @media screen and (min-width: 730px) {
                            font-size:1.6em ;
                        }
                        */
                    }
                }
            }
        }
    }
</style>
