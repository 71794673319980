<template>
    <div id="publics">
        <div class="containerAffichage" id="publicsContainerAffichage">

<!-- <transition-expand> -->
<transition name="fade-slide">
        <div v-if="!addeoSmall && (itemCourant != '')" id="itemCourant"  @click.stop="reinit('');" >
                        <interaction-public :data="lesDatas" :item="itemCourant" />
        </div>
</transition>
<transition name="fade-marge">
        <div v-if="!addeoSmall && (itemCourant == '')" >
            <div class="marge haut" >
            </div>
        </div>
</transition>

<!-- </transition-expand> -->

            <div id="items">

<transition name="fade">
                <div v-if="addeoSmall || (itemCourant != 'formation')" class="item" id="formation" @click.stop="reinit('formation');">
                    <item-public :data="lesDatas" item="formation" />
                </div>
</transition>
<transition name="fade">
                <div v-if="addeoSmall || (itemCourant != 'emploi')"  class="item" id="emploi" @click.stop="reinit('emploi');">
                    <item-public :data="lesDatas" item="emploi" />
                </div>
</transition>
<transition name="fade">
                <div v-if="addeoSmall || (itemCourant != 'salarie')"  class="item" id="salarie" @click.stop="reinit('salarie');">
                    <item-public :data="lesDatas" item="salarie" />
                </div>
</transition>
<transition name="fade">
                <div v-if="addeoSmall || (itemCourant != 'etudes')"  class="item" id="etudes" @click.stop="reinit('etudes');">
                    <item-public :data="lesDatas" item="etudes" />
                </div>
</transition>
            </div>

<transition name="fade-marge">
        <div v-if="!addeoSmall && (itemCourant == '')" >
            <div class="marge bas" >
            </div>
        </div>
</transition>

        </div>
    </div>
</template>

<script>
    import Public from '@/components/header/public/Public.vue'
    import PublicI from '@/components/header/public/PublicI.vue'
//    import TransitionExpand from '@/components/lib/Transition.vue'

export default {
    name: 'Publics',
    components: {
        'item-public': Public,
        'interaction-public': PublicI,
//        'transition-expand': TransitionExpand,
        },
    props: {
    },
    data() {
        return {
            itemCourant : "",
            images : {"formation":"formation", "emploi":"emploi", "salarie":"salarie", "etudes":"etudes"},

            items : {"formation":false,
                "emploi":false,
                "salarie":false,
                "etudes":false},
            itemsTxt : {"formation":"Quand<br/>je suis en <span class='important'>formation</span>",
                "emploi":"Quand<br/>je cherche un <span class='important'>emploi</span>",
                "salarie":"Quand<br/>je suis en<br/><span class='important'>activité</span>",
                "etudes":"Quand<br/>je fais des<br/><span class='important'>études</span>"},
            itemsTxtS : {"formation":"Quand je suis en <span class='important'>formation</span>",
                "emploi":"Quand je cherche un <span class='important'>emploi</span>",
                "salarie":"Quand je suis en <span class='important'>activité</span>",
                "etudes":"Quand je fais des <span class='important'>études</span>"},
            itemsCont : {"formation":["Je fais le point sur ce que je sais faire",
                                      "Je valorise ce que j’apprends pendant ma formation",
                                      "Je prépare ma sortie de formation en créant des CV"],
                "emploi":["Je fais le point sur ce que je sais faire",
                          "Je m'oriente vers un nouveau métier",
                          "Je trouve une formation",
                          "Je crée des CV",
                          "Je présente ma candidature de façon originale",
                          "Je prépare un entretien d'embauche"],
                "salarie":["Je fais le point sur ce que je sais faire",
                           "Je m'oriente vers un nouveau métier",
                           "Je prépare mon entretien professionnel annuel",
                           "Je trouve une formation professionnelle",
                           "Je fais reconnaître mon expérience"],
                "etudes":["Je fais le point sur ce que j'ai appris",
                          "Je prépare mon entrée dans la vie professionnelle en créant des CV",
                          "Je me réoriente"]},
                "imgsGauche":[],
                "imgsDroite":[],
                "imgsMev":[],
                "imgs":[],
            tmp:true
        }
    },
    created() {
        this.preloadImages([
            "/img/publics/bulles/gauche1.svg",
            "/img/publics/bulles/gauche2.svg",
            "/img/publics/bulles/gauche3.svg",
            "/img/publics/bulles/droite1.svg",
            "/img/publics/bulles/droite2.svg",
            "/img/publics/bulles/droite3.svg",
            "/img/publics/mev-formation.svg",
            "/img/publics/mev-emploi.svg",
            "/img/publics/mev-salarie.svg",
            "/img/publics/mev-etudes.svg",
            "/img/publics/formation.svg",
            "/img/publics/emploi.svg",
            "/img/publics/salarie.svg",
            "/img/publics/etudes.svg"
            ], function(){
                console.log('All images were loaded');
                //this.ready=1;
            });
    },
    computed: {
        lesDatas() {
            var datas = new Object();
            datas.images= this.images;
            datas.items= this.items;
            datas.itemsTxt= this.itemsTxt;
            datas.itemsTxtS= this.itemsTxtS;
            datas.itemsCont= this.itemsCont;
            datas.imgsGauche = this.imgsGauche; // l'appel par img.src ne sera pas nécessaire, mais - semble-t-il - le passage du tableau d'image si ... je ne comprends pas vraiment pourquoi
            datas.imgsDroite = this.imgsDroite;
            datas.imgsMev = this.imgsMev;
            datas.imgs = this.imgs;
            return(datas);
        },
        addeoSmall() {
            return(this.$parent.$parent.addeoSmall);
        },
        addeoMedium() {
            return(this.$parent.$parent.addeoMedium);
        },
        addeoLarge() {
            return(this.$parent.$parent.addeoLarge);
        }
    },
    methods: {
        isInViewport(element) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        reinit(item) {
            var element_to_scroll_to = document.getElementById('publics');
            if (!this.isInViewport(element_to_scroll_to)) {
                element_to_scroll_to.scrollIntoView({behavior: "smooth"});
            }

            item !== "formation" ? this.items["formation"]=false : null
            item !== "emploi" ? this.items["emploi"]=false : null
            item !== "salarie" ? this.items["salarie"]=false : null
            item !== "etudes" ? this.items["etudes"]=false : null
            this.items[item]=!this.items[item];
            if (this.items[item]) {
                this.itemCourant = item;
            }
            else {
                this.itemCourant = "";
            }
        },
        preloadImages(urls, allImagesLoadedCallback){
            var thisObj = this;
            var loadedCounter = 0;
            var toBeLoadedNumber = urls.length;
            var counter = 0;
            urls.forEach(function(url){
                thisObj.preloadImage(url, counter, function(){
                    loadedCounter++;
                    // console.log('Number of loaded images: ' + loadedCounter);
                    if(loadedCounter == toBeLoadedNumber){
                        allImagesLoadedCallback();
                    }
                });
                counter++;
            });
        },
        preloadImage(url, counter, anImageLoadedCallback){
            var img = new Image();
            img.onload = anImageLoadedCallback;
            img.src = url;
            if (counter < 3) {
                this.imgsGauche[counter+1] = img;
            }
            else {
                if (counter < 6) {
                    this.imgsDroite[counter-2] = img;
                }
                else {
                    if (counter == 6) {
                        this.imgsMev["formation"] = img;
                    }
                    if (counter == 7) {
                        this.imgsMev["emploi"] = img;
                    }
                    if (counter == 8) {
                        this.imgsMev["salarie"] = img;
                    }
                    if (counter == 9) {
                        this.imgsMev["etudes"] = img;
                    }
                    if (counter == 10) {
                        this.imgs["formation"] = img;
                    }
                    if (counter == 11) {
                        this.imgs["emploi"] = img;
                    }
                    if (counter == 12) {
                        this.imgs["salarie"] = img;
                    }
                    if (counter == 13) {
                        this.imgs["etudes"] = img;
                    }
                }
            }

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

    .addeoMedium, .addeoLarge {
        #publics {

            .containerAffichage {
                margin-top : 40px;
                margin-bottom : 20px;
/*
                .legende {
                    font-size:1.1em ;
                    span {
                        font-size:1.1em ;
                    }
                }
*/
            }
           .marge {
               &.haut {
                min-height:100px;
               }
               &.bas {
                min-height:75px;
               }
            }
        }
    }
    .addeoMedium, {
        #publics {
            .marge {
                min-height:25px !important;
            }
            #items {
                max-width: 990px;
                margin:2em auto 0;

                .legende {
                    /*font-size:1.1em ;*/
                    font-size:0.8em ;
                    span {
                    }
                }
            }
        }
    }

    .addeoSmall {
        #publics {
            border: none;
        }
    }

    #publics {
        width:100%;
        height:auto;
        background-color: transparent;
        padding-bottom: 1em;
        box-sizing: border-box !important;
        border: 1px solid transparent;
        border-bottom-color: white;


        #itemCourant {
            /*max-height:400px;*/
/*
            img {
                z-index: 12;
                position: relative;
            }

            .fond.toujours {
                display:block !important;
                opacity:0.4;
            }
*/
        }


        .item {
            width:22%;
            max-width:315px;
            overflow: hidden;
            padding-bottom: 1%;
            cursor:pointer;
        }
        .item, #itemCourant .containerPublicI .publicI {
            display:inline-block;

            img {
                max-width:100%;
                z-index: 12;
                position: relative;

                &.survol {
                    opacity:0.4;
                }

            }
            .public:focus {
                opacity:0.8;
            }
            .public:hover {
            /*
                img {
                    &.mev {
                        display:block;
                        }
                    &.normal {
                        display:none;
                    }
                }
            */

                .legende {
                    span {

                        span.important {
                            color:#FFD401;
                        }
                    }
                }
            }
            .ombre {
                width:100%; // TODO le pourcentage exact une fois les bonnes ombres livrées */
                position:absolute;
                top:0;
                right:0;
                z-index:10;
                opacity:0.3;
            }
            .fond {
                position:absolute;
                top:0;
                left:0;
                z-index:13;
                &.derriere {
                    z-index:11;
                }
            }
            .dessus {
                position:absolute;
                top:0;
                left:0;
                z-index:19;
                opacity: 0.7;
                cursor:pointer;
            }
            .legende, .contenu {
                font-family: Montserrat, Roboto, Arial, sans-serif;
                font-weight: 400;
                font-size:1.8vw ;
                @media screen and (min-width: 1200px) {
                    font-size:1.3em ;
                }
                position: absolute;
                width: 60%;
                top: 50%;
                left: 50%;
                right: 0;
                height: 22%;
                background-color: transparent;
                color: white;
                vertical-align: middle;
                text-align: center;
                transform: translateX(-50%) translateY(-50%);
                z-index:14;

                > span {
                    padding: 0 4em;
                    display: inline-block;
                    position: absolute;
                    text-align: center;
                    left: 0px;
                    max-width: 100%;
                    width: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);

                    .important {
                        display:inline-block;
                        font-weight: 600;
                        font-size:105%;
                    }
                }
            }
            .legende {
                    span {
                        line-height: 1.5;
                    }
                }
            .legende.titre {

                top:5%;
                height:40%;

                background-color: #F5CA01 !important;
                span {
                    /*
                    padding-top: 15%;
                    */
                    color: #356974;

                }
            }
            .contenu {
                font-weight: normal;
                height:52%;
                top:40%;
                color:#356974;
                background-color: #EDF2F5;
                span {
                    /*
                    padding-top: 15%;
                    */
                }
            }

            .survol {
                display:none;
            }
            &:hover, &:focus {
                .fond {
                    display:none;
                }
                .survol {
                    display:block;
                }
            }

        }








        /* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter {
  transform: translateY(30%);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: scale(0);
  opacity: 0;
}

.fade-enter-active {
  transition: all .3s ease;
    max-width: 315px;
}
.fade-leave-active {

    max-width: 315px;
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  /*transition: all .3s ease-in-out;*/
}
.fade-enter, .fade-leave-to {
    transform: scale(0);
    max-width: 0;
    opacity: 0;
}
.fade-slide-enter-active, .fade-leave-active, .fade-slide-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    max-height:400px;
    /*
    transform: scale(1);
    opacity: 1;
    */
}
.fade-slide-enter, .fade-slide-leave-to {

    transform: scale(0);
    opacity: 0;

    max-height:0;
}
.fade-marge-enter-active, .fade-marge-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    max-height:150px;
}
.fade-marge-enter, .fade-marge-leave-to {
    opacity: 0;
    max-height:0;
}
/*
.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
}
.expand-enter,
.expand-leave-to {
  opacity: 0;
}
*/
    }
</style>
