/*
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
*/

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

function getTimeMSec() {
    return(Date.now());
}

export default new Vuex.Store({
  state: {
      cacheAddeo:null,
  },

  mutations: {
      setCacheAddeo(state, datas) {
          state.cacheAddeo = datas;
	  },
  },


  actions: {

  },

  getters: {
    cacheAddeo: state => state.cacheAddeo,
  },

    plugins: [createPersistedState()]
})
